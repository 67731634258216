import './style.css';

const QuoteWizardNavigation = (props) => {
	const handleWizardNavClick = (e) => {
		if (!props.showAllSections) {
			props.handleClick(e.target.dataset.index);
		}
	};

	const servicesError = () => {
		return props.errors.priority_type || props.errors.recordal_type || props.errors.service_id || props.errors.application_technologies ? true : false;
	};

	const IdentifiersError = () => {
		return props.errors.application_number || props.errors.client_id ? true : false;
	};

	return (
		<header className={`new-quote-wizard__header`}>
			<div
				className={`new-quote-wizard__nav-pill ${props.activeIndex >= 1 || props.showAllSections ? 'new-quote-wizard__nav-pill-active' : ''} ${props.activeIndex > 1 || props.showAllSections ? 'no-radius-right' : ''}`}
				data-index={1}
				onClick={handleWizardNavClick}
			>
				<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 1 ? 'current-active' : ''}`}>
					<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex > 1 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 1 ? 'current-active' : ''}`}>1</span>
					Services &amp; Technologies
				</div>
				<i className={`new-quote-wizard__nav-pill-error-indicator ${servicesError() ? 'show-error' : ''}`}></i>
			</div>

			<div
				className={`new-quote-wizard__nav-pill ${props.activeIndex >= 2 || props.showAllSections ? 'new-quote-wizard__nav-pill-active' : ''} ${props.activeIndex > 2 || props.showAllSections ? 'no-radius-right no-radius-left' : ''} ${props.activeIndex == 2 ? 'no-radius-left' : ''}`}
				data-index={2}
				onClick={handleWizardNavClick}
			>
				<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 2 ? 'current-active' : ''}`}>
					<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex > 2 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 2 ? 'current-active' : ''}`}>2</span>
					Identifiers
				</div>
				<i className={`new-quote-wizard__nav-pill-error-indicator ${IdentifiersError() ? 'show-error' : ''}`}></i>
			</div>

			<div
				className={`new-quote-wizard__nav-pill ${props.activeIndex >= 3 || props.showAllSections ? 'new-quote-wizard__nav-pill-active' : ''} ${props.activeIndex > 3 || props.showAllSections ? 'no-radius-right no-radius-left' : ''} ${props.activeIndex == 3 ? 'no-radius-left' : ''}`}
				data-index={3}
				onClick={handleWizardNavClick}
			>
				<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 3 ? 'current-active' : ''}`}>
					<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex > 3 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 3 ? 'current-active' : ''}`}>3</span>
					Regions
				</div>
				<i className={`new-quote-wizard__nav-pill-error-indicator ${props.errors.regions ? 'show-error' : ''}`}></i>
			</div>

			<div
				className={`new-quote-wizard__nav-pill ${props.activeIndex >= 4 || props.showAllSections ? 'new-quote-wizard__nav-pill-active' : ''} ${props.activeIndex > 4 || props.showAllSections ? 'no-radius-right no-radius-left' : ''} ${props.activeIndex == 4 ? 'no-radius-left' : ''}`}
				data-index={4}
				onClick={handleWizardNavClick}
			>
				<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 4 ? 'current-active' : ''}`}>
					<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex > 4 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 4 ? 'current-active' : ''}`}>4</span>
					Details
				</div>
				<i className={`new-quote-wizard__nav-pill-error-indicator ${props.errors?.quote_details_header_error ? 'show-error' : ''}`}></i>
			</div>

			<div
				className={`new-quote-wizard__nav-pill ${props.activeIndex >= 5 || props.showAllSections ? 'new-quote-wizard__nav-pill-active' : ''} ${props.activeIndex > 5 || props.showAllSections ? 'no-radius-right no-radius-left' : ''} ${props.activeIndex == 5 ? 'no-radius-left' : ''}`}
				data-index={5}
				onClick={handleWizardNavClick}
			>
				<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 5 ? 'current-active' : ''}`}>
					<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex > 5 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 5 ? 'current-active' : ''}`}>5</span>
					Additional Info &amp; Attachments
				</div>
				<i className={`new-quote-wizard__nav-pill-error-indicator ${props.errors?.agree_to_terms ? 'show-error' : ''}`}></i>
			</div>

			{props.showAssociateSelection ?
				<div
					className={`new-quote-wizard__nav-pill ${props.activeIndex == 6 || props.showAllSections ? 'new-quote-wizard__nav-pill-active no-radius-left' : ''}`}
					data-index={6}
					onClick={handleWizardNavClick}
				>
					<div className={`new-quote-wizard__nav-pill-text-wrapper ${props.activeIndex == 6 ? 'current-active' : ''}`}>
						<span className={`new-quote-wizard__nav-pill-number ${props.activeIndex == 6 || props.showAllSections ? 'active' : ''} ${props.activeIndex == 6 ? 'current-active' : ''}`}>
							6
						</span>
						Agent Selection
					</div>
					<i className={`new-quote-wizard__nav-pill-error-indicator ${props.errors?.associate_tab ? 'show-error' : ''}`}></i>
				</div>
			:	null}
		</header>
	);
};

export default QuoteWizardNavigation;

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { RoleBox } from 'com/util/RoleBox';
import AppTemplate from 'com/templates/ApplicationTemplate';
import { AppRoute } from 'com/util/AppRoute';
import AppLink from 'com/util/AppLink';
import Routes from 'data/routes';
import Modal from 'com/widgets/Modal';

import UserDetailsMain from './UserDetailsMain';
import UserDetailsExpertiseRegions from './UserDetailsExpertiseRegions';
import UserDetailsRates from './UserDetailsRates';
import UserDetailsPoints from './UserDetailsPoints';
import UserDetailsPreferredAssociates from './UserDetailsPreferredAssociates';
import UserDetailsBlacklistedAssociates from './UserDetailsBlacklistedAssociates';
import UserDetailsCommunicationPreferences from './UserDetailsCommunicationPreferences';

import Checkbox from 'com/ui/Checkbox';
import Button from 'com/ui/Button';
import DocumentUploader from 'com/widgets/DocumentUploader';
import SocialBox from 'com/widgets/SocialBox';
import LoaderOverlay from 'com/ui/LoaderOverlay';

import { EXTERNAL_ROLES, PAGE_TITLE, PREFERENCES_GROUP } from 'data/constants';

import ICON_DEFAULT_AVATAR from 'assets/images/icons/avatar_placeholder_invert.svg';
import ICON_SIDEBAR_SOCIAL from 'assets/images/icons/ico_sidebar_title_social.svg';
import ICON_SOCIAL_FACEBOOK from 'assets/images/icons/ico_social_facebook.svg';
import ICON_SOCIAL_LINKEDIN from 'assets/images/icons/ico_social_linkedin.svg';
import ICON_SOCIAL_TWITTER from 'assets/images/icons/ico_social_twitter.svg';

import JWT from 'services/jwt';
import Auth from 'services/rest/auth';
import Users from 'services/rest/users';
import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';
import Storage from 'services/rest/storage';
import AppDate from 'com/util/AppDate';
import { formatString, random } from 'services/strings';

import { INTERNAL_ROLES } from 'data/constants';
import { ASSOCIATE_TYPE } from 'data/constants';

import useNotification from 'services/hooks/use_notification';
import useTitle from 'services/hooks/useTitle';
import Preferences from 'services/rest/preferences';

import './style.css';
import { OwnerContextProvider } from 'util/ownerContext';
import { setIsUpdated } from 'redux/ducks/redirect';
import { setUserDataAsync } from 'redux/ducks/auth';

import { formatWebLink } from 'services/strings';

const UserDetails = () => {
	const ref = useRef(null);
	const { id } = useParams();
	const user = useSelector((state) => state.auth.user);

	const userDataInit = {
		id: id,
		first_name: '',
		last_name: '',
		title: '',
		email: '',
		position: '',
		role_id: '',
		email_for_quotes: '',
		email_for_cases: '',
		is_active: null,
		bio: '',
		is_centralized: true,
		firm_data: {
			name: '',
			website: '',
		},
		expertise: [],
		regions: [],
		points: [],
		casesRegionsPoints: [],
		adjustedPoints: [],
		tos_agreement: false,
		tos_agreement_date: '',
		days_required_rush: '',
		social_facebook: '',
		social_linkedin: '',
		social_twitter: '',
		rates: [],
		firm_rates: [],
		preferred_associates: [],
		blacklisted_associates: [],
		communication_preferences: [],
	};

	const updatedTabsInit = {
		personal_details: false,
		expertise_regions: false,
		rates: false,
		preferred_associates: false,
		blacklisted_associates: false,
		points: false,
		communication_preferences: false,
	};

	const erroredTabsInit = {
		personal_details: false,
	};

	const mainDataErrorsInit = {
		days_required_rush: '',
	};

	// const userSessionData = useSelector(state => state.auth.user);
	const [userData, setUserData] = useState(userDataInit);
	// const [ servicesData, setServicesData ] = useState([]);
	const [userID, setUserID] = useState();
	const [updatedTabs, setUpdatedTabs] = useState(updatedTabsInit);
	const [erroredTabs, setErroredTabs] = useState(erroredTabsInit);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [firmID, setFirmID] = useState('noid');
	const [mainDataErrors, setMainDataErrors] = useState(mainDataErrorsInit);
	const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
	const [avatarUrl, setAvatarUrl] = useState(null);
	const sendNotification = useNotification();
	const dispatch = useDispatch();
	// const isUpdated = useSelector(state => state.redirect.isUpdated);
	const history = useHistory();
	const title = `${userData.first_name} ${userData.last_name} ${PAGE_TITLE.USER_DETAILS}`;
	useTitle(title);

	useEffect(async () => {
		try {
			setShowLoader(true);
			let uid = id;
			if (id === 'me') {
				uid = user.uid;
			} else {
				uid = id;
			}
			setUserID(uid);

			// let sData = await Settings.GetServices()
			let uData = await Users.GetOne(uid);
			let pData = await Users.GetPoints(uid);
			let cpData = await Preferences.GetUserRecordsById(uid, PREFERENCES_GROUP.GENERAL_SETTINGS);

			getAvatarUrl(uData.image);

			setUserData({
				...uData,
				points: pData,
				casesRegionsPoints: generateCasesRegionsPoints(pData),
				communication_preferences: cpData,
			});
			setFirmID(uData.firm_id);
			setShowLoader(false);
		} catch (err) {
			dispatch(setIsUpdated(false));
			setShowLoader(false);
			if (err.code && err.code === 'ABAC_FORBIDDEN') return history.replace('/');
			console.log(err);
		}
	}, [id]);

	const generateCasesRegionsPoints = (points) => {
		let casesRegionsPoints = [];
		let balance = 0;

		// generate rows with total balance, including all adjustments if any
		points.reduce((res, value) => {
			let key = value.case_id + '-' + value.region_id;
			if (!res[key]) {
				res[key] = { ...value, amount: 0 };
				casesRegionsPoints.push(res[key]);
			}
			res[key].amount += parseInt(value.amount);
			return res;
		}, {});

		// append adjustments rows data in the parent row data
		casesRegionsPoints = casesRegionsPoints.map((crp) => ({
			...crp,
			adjustments: points.filter((p) => p.case_region_id === crp.case_region_id).sort((a, b) => new Date(b._modified) - new Date(a._modified)),
		}));
		return casesRegionsPoints;
	};

	/* const handlePoints = (points) => {
        let balance;
        points = points.reverse().map(p => {
            p.balance = (balance || 0) + parseInt(p.amount);
            return {
                ...p,
                balance: balance
            }
        }).reverse();

        return points;
    }; */

	const onMainDataChange = (e) => {
		setUserData({
			...userData,
			[e.target.name]: e.target.value,
		});
		setUpdatedTabs({
			...updatedTabs,
			personal_details: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onExpertiseChange = (technologyID, selected) => {
		if (selected) {
			setUserData({
				...userData,
				expertise: [...userData.expertise, { technology_id: technologyID, user_id: userData.id }],
			});
		} else {
			setUserData({
				...userData,
				expertise: [...userData.expertise.filter((e) => technologyID !== e.technology_id)],
			});
		}
		setUpdatedTabs({
			...updatedTabs,
			expertise_regions: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeRegions = (region, action) => {
		switch (action) {
			case 'add':
				setUserData({
					...userData,
					regions: [...(userData.regions ? userData.regions : []), { user_id: userData.id, region_code: region.code, region_id: region.id, region_name: region.name, is_archived: 0 }],
				});
				break;
			case 'archive':
				setUserData({
					...userData,
					regions: userData.regions.map((r) => {
						if (r.region_id === region.id) {
							r.is_archived = r.is_archived ? 0 : 1;
						}
						return r;
					}),
				});
				break;
		}
		setUpdatedTabs({
			...updatedTabs,
			rates: true,
			expertise_regions: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeRate = (field, region_id, service_id, client_id, value, rate_id) => {
		let defaultValues = {
			region_id: region_id,
			service_id: service_id,
			available: false,
			rate_professional: 0,
			rate_translation_type: 'WORD',
			rate_translation: 0,
			currency: 'USD',
			object_type: 'USER',
			override: false,
			rate_confirmed: false,
			rate_confirmed_by: '',
			client_id: client_id,
			associate_type: client_id === userID ? ASSOCIATE_TYPE.IN : ASSOCIATE_TYPE.OON,
		};
		// check if any records exist
		let empty = userData.rates === null;
		// check if specified record exists (if not empty)
		let exists = !empty && userData.rates.filter((r) => r.service_id === service_id && r.region_id === region_id && r.client_id === client_id).length > 0;

		switch (field) {
			case 'available':
				if (empty || (!empty && !exists)) {
					setUserData({
						...userData,
						rates: [...(userData.rates ? userData.rates : []), { ...defaultValues, available: value === 'on', is_changed: true }],
					});
				}
				if (exists) {
					setUserData({
						...userData,
						rates: userData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.available = !r.available;
							}
							return r;
						}),
					});
				}
				break;
			case 'rate_confirmed':
				if (empty || (!empty && !exists)) {
					setUserData({
						...userData,
						rates: [...(userData.rates ? userData.rates : []), { ...defaultValues, rate_confirmed: value === 'on', is_changed: true }],
					});
				}
				if (exists) {
					setUserData({
						...userData,
						rates: userData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.rate_confirmed = !r.rate_confirmed;
							}
							return r;
						}),
					});
				}
				break;
			case 'currency':
				if (empty || (!empty && !exists)) {
					setUserData({
						...userData,
						rates: [...(userData.rates ? userData.rates : []), { ...defaultValues, currency: value, is_changed: true }],
					});
				}
				if (exists) {
					setUserData({
						...userData,
						rates: userData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r.currency = value;
							}
							return r;
						}),
					});
				}
				break;
			case 'revert':
				if (!empty) {
					console.log('revert');
					let reverted = userData.rates.filter((r) => {
						let v = r.region_id !== region_id || r.service_id !== service_id || r.client_id !== client_id;
						console.log(v);
						return v;
					});
					console.log(reverted);
					setUserData({
						...userData,
						rates: reverted,
					});
				}
				break;
			case 'region_currency':
				if (!empty && value) {
					setUserData({
						...userData,
						rates: userData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.client_id === client_id) {
								r.currency = value;
								r.is_changed = true;
							}
							return r;
						}),
					});
				}
				break;
			default:
				if (empty || (!empty && !exists)) {
					setUserData({
						...userData,
						rates: [...(userData.rates ? userData.rates : []), { ...defaultValues, [field]: value, is_changed: true }],
					});
				}
				if (exists) {
					setUserData({
						...userData,
						rates: userData.rates.map((r) => {
							if (r.id === rate_id) {
								r.is_changed = true;
							}
							if (r.region_id === region_id && r.service_id === service_id && r.client_id === client_id) {
								r[field] = value;
							}
							return r;
						}),
					});
				}
				break;
		}
		setUpdatedTabs({
			...updatedTabs,
			rates: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangePoints = (data) => {
		let adjustedPoints = data.map((ap) => {
			let pid = ap.id;
			delete ap.id;

			return {
				...ap,
				_created: new Date(),
				_modified: new Date(),
				internal_user_id: user.uid,
				points_adjusted_by: user.name,
				parent_id: pid,
			};
		});
		let updatedPoints = userData.points.concat(...adjustedPoints);
		setUserData({
			...userData,
			points: updatedPoints,
			adjustedPoints: adjustedPoints,
			casesRegionsPoints: generateCasesRegionsPoints(updatedPoints),
		});

		setUpdatedTabs({
			...updatedTabs,
			points: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangePreferredAssociates = (data) => {
		setUserData({
			...userData,
			preferred_associates: data,
		});
		setUpdatedTabs({
			...updatedTabs,
			preferred_associates: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeBlacklistedAssociates = (data) => {
		setUserData({
			...userData,
			blacklisted_associates: data,
		});
		setUpdatedTabs({
			...updatedTabs,
			blacklisted_associates: true,
		});
		dispatch(setIsUpdated(true));
	};

	const onChangeCommunicationPreferences = (data) => {
		let pref = userData.communication_preferences.find((p) => p.preference_key === data.preference_key);
		if (!pref) {
			setUserData((prevState) => {
				return { ...prevState, communication_preferences: [...userData.communication_preferences, data] };
			});
		} else {
			setUserData((prevState) => {
				return { ...prevState, communication_preferences: [...userData.communication_preferences.filter((p) => p.preference_key !== data.preference_key), data] };
			});
		}
		setUpdatedTabs({
			...updatedTabs,
			communication_preferences: true,
		});
	};

	// const onTOSChange = (e) => {
	//     setUserData({ ...userData, tos_agreement: !userData.tos_agreement });
	// }

	const updateUIPointsTable = async () => {
		let points = await Users.GetPoints(userData.id);
		setUserData({
			...userData,
			points: points,
			casesRegionsPoints: generateCasesRegionsPoints(points),
			adjustedPoints: [],
		});
	};

	const validateMainUserData = () => {
		let errs = {};
		let out = true;
		for (let fld in mainDataErrors) {
			let field = userData[fld] !== undefined && userData[fld] !== null && userData[fld] !== '' ? userData[fld] + '' : '';
			let e = '';
			if (field < 0) {
				e = 'Enter a positive value';
				out = false;
			}
			errs = {
				...errs,
				[fld]: e,
			};
		}
		setMainDataErrors(errs);
		setErroredTabs({
			...erroredTabs,
			personal_details: true,
		});
		return out;
	};

	const saveUserDetails = async () => {
		if (!validateMainUserData()) return;
		setErroredTabs(erroredTabsInit);
		setShowSpinner(true);
		let ratesPayload = [];
		try {
			if (userData.expertise && userData.expertise.length >= 0 && updatedTabs.expertise_regions) await Users.UpdateExpertise(userID, userData.expertise);
			if (userData.regions && userData.regions.length > 0 && updatedTabs.expertise_regions) await Users.UpdateRegions(userID, userData.regions);
			if (userData.rates && userData.rates.length > 0 && updatedTabs.rates) {
				ratesPayload = userData.rates.filter((r) => r.is_changed === true);
				if (ratesPayload.length > 0) await Users.UpdateRates(userID, ratesPayload);
			}
			if (userData.preferred_associates && !!updatedTabs.preferred_associates && updatedTabs.preferred_associates) await Users.UpdatePreferredAssociates(userID, userData.preferred_associates);
			if (userData.blacklisted_associates && !!updatedTabs.blacklisted_associates && updatedTabs.blacklisted_associates)
				await Users.UpdateBlacklistedAssociates(userID, userData.blacklisted_associates);
			if (userData.communication_preferences && !!updatedTabs.communication_preferences && updatedTabs.communication_preferences) await Preferences.Update(userData.communication_preferences);
			if (userData.adjustedPoints && userData.adjustedPoints.length > 0 && updatedTabs.points) {
				for (let ap of userData.adjustedPoints) {
					await Users.ApplyAdjustedPoints(userID, ap);
				}
				await updateUIPointsTable();
			}
			if (updatedTabs.personal_details) await Users.Update(userID, packUserData());
			Object.keys(updatedTabs).map((u) => {
				if (updatedTabs[u] !== true) {
					return u;
				}
				u = formatString(u);
				EventLoggerEvents.EventLogger({ event: `User ${u} Tab Updated`, data: { linkedFirm: userData.firm_data.name, user: user } });
			});
			// if rates are changed they need to be re-fetched
			if (ratesPayload.length > 0) {
				let rates = await Users.GetRatesByUserID(userID);
				setUserData((prevState) => {
					return {
						...prevState,
						rates: rates,
					};
				});
			}
			setUpdatedTabs(updatedTabsInit);
			sendNotification({ type: 'success', title: 'Successfully saved user details data' });
			dispatch(setIsUpdated(false));
			setShowSpinner(false);
		} catch (err) {
			console.log(err);
			sendNotification({ type: 'error', title: 'Unsuccessfully saved user details data' });
		}
	};

	// The state contains additional fields that are not part of the user's update payload. We need to filter them out.
	const packUserData = () => {
		let allowedProps = [
			'_deleted',
			'xid',
			'email',
			'first_name',
			'last_name',
			'title',
			'is_confirmed',
			'is_active',
			'role_id',
			'image',
			'firm_id',
			'is_centralized',
			'does_accept_urgent_cases',
			// 'tos_agreement',
			'position',
			'bio',
			'email_for_quotes',
			'email_for_cases',
			'social_facebook',
			'social_linkedin',
			'social_twitter',
			'days_required_rush',
		];
		const filtered = allowedProps.reduce((obj, key) => {
			obj[key] = userData[key];
			return obj;
		}, {});
		return filtered;
	};

	const showTosAgreementDate = () => {
		return !!userData.tos_agreement_date ? userData.tos_agreement_date : '';
	};

	const openDeactivateModal = () => {
		setDeactivateModalOpen(true);
	};

	const closeDeactivateModal = () => {
		setDeactivateModalOpen(false);
	};

	const toggleUserStatus = async () => {
		try {
			setShowLoader(true);
			await Users.Update(userData.id, { ...userData, is_active: !userData.is_active });
			setUserData((prevState) => {
				return { ...prevState, is_active: !prevState.is_active };
			});
			setDeactivateModalOpen(false);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const deactivateModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeactivateModal, theme: 'azami-ghost' },
		{ primary: true, label: userData.is_active ? 'Confirm Deactivation' : 'Confirm Activation', action: toggleUserStatus, theme: 'azami-blue' },
	];

	const triggerFileselector = (e) => {
		ref.current.click();
	};

	const uploadImage = async (e) => {
		try {
			if (e.target.files.length === 0) return;
			const result = await Storage.UploadImage('avatars', 'image', e.target.files[0]);
			if (!result.filename) return sendNotification({ type: 'error', title: 'Avatar not saved' });
			// rates payload is set to [] bacause it can get big and cause payload too large error. It is not needed when updating user data
			await Users.Update(userData.id, { ...userData, image: result.filename, rates: [], firm_rates: [] });
			setUserData({
				...userData,
				image: result.filename,
			});
			let token = await Auth.UpdateTokenData();
			window.localStorage.setItem('token', token);
			let res = await Auth.ValidateToken();
			if (res === token) {
				const user = JWT.parseJWT(res);
				/**
				 * Because the name of the image is the id of the user, when uploading new image react doesn't detect the change
				 * and the UserMenu component isn't rerendered .
				 * By changing the image prop value in the global (redux) state to empty string and then to the actual image path,
				 * we force the UserMenu component to rerender and show to correct image.
				 */
				await setUserDataAsync(dispatch, { ...user, image: '' });
				await setUserDataAsync(dispatch, user);
			}
			let string = random(20);
			setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${result.filename}?${string}`);
			sendNotification({ type: 'success', title: 'Avatar saved' });
		} catch (err) {
			sendNotification({ type: 'error', title: 'Avatar not saved' });
		}
	};

	const getAvatarUrl = async (image) => {
		if (image) {
			let string = random(20);
			setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}?${string}`);
		} else {
			setAvatarUrl(ICON_DEFAULT_AVATAR);
		}
	};

	return (
		<AppTemplate title="User Details" headerIcon="user_management" collapsibleSidebar={false}>
			<AppTemplate.Sidebar>
				<OwnerContextProvider userId={userData.id}>
					<div className="user-sidebar">
						<div className="user-sidebar__image-container">
							<picture>
								<source srcSet="" />
								<img className="user-sidebar__image-container__avatar" src={avatarUrl} alt="" />
							</picture>
							<RoleBox roles={['$user']}>
								<button className="user-sidebar__image-container__btn-change-avatar" onClick={triggerFileselector}>
									<input id="avatar-upload" name="image" type="file" hidden onChange={uploadImage} ref={ref} />
								</button>
							</RoleBox>
						</div>
						<div className="user-sidebar__user-info">
							<span className="user-sidebar__user-info__name">
								{userData.first_name} {userData.last_name}
							</span>
							<span className="user-sidebar__user-info__positon-company">
								{userData.position ? `${userData.position} - ` : ''}
								{userData.firm_data.name}
							</span>
							{userData.firm_data.website != '' ?
								<a className="user-sidebar__user-info__company-website" href={formatWebLink(userData.firm_data.website)} target="_blank">
									{userData.firm_data.website}
								</a>
							:	null}
						</div>
						{/* THE CODE FROM LINE 588 TO LINE 609 IS COMMENTED BECAUSE FOR NOW IT'S NOT USED, BUT MAY BE IN THE FUTURE*/}
						{/* <div className="user-sidebar__section">
                            <div className="user-sidebar__section__title">
                                <img src={ICON_SIDEBAR_SOCIAL} />
                                Social media
                            </div>
                            <div className="user-sidebar__section__body">
                                <ul className="user-sidebar__section__body__social-links">
                                    <li>
                                        <SocialBox icon={ICON_SOCIAL_FACEBOOK} title="Facebook" titleClassName="facebook" name="social_facebook" value={userData.social_facebook} onChange={onMainDataChange} />
                                    </li>
                                    <li>
                                        <SocialBox icon={ICON_SOCIAL_LINKEDIN} title="Linkedin" titleClassName="linkedin" name="social_linkedin" value={userData.social_linkedin} onChange={onMainDataChange} />
                                    </li>
                                    <li>
                                        <SocialBox icon={ICON_SOCIAL_TWITTER} title="Twitter" titleClassName="twitter" name="social_twitter" value={userData.social_twitter} onChange={onMainDataChange} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="user-sidebar__uploader">
                            <DocumentUploader title="Upload Documents" />
                        </div> */}
						<RoleBox roles={INTERNAL_ROLES}>
							{userData.is_active === null ?
								null
							: userData.is_active ?
								<Button theme="grey" className="user-sidebar__deactivate__btn" onClick={openDeactivateModal}>
									Deactivate User
								</Button>
							:	<Button className="user-sidebar__deactivate__btn" onClick={openDeactivateModal}>
									Activate User
								</Button>
							}
						</RoleBox>

						{deactivateModalOpen ?
							<Modal title={userData.is_active ? 'Deactivate User' : 'Activate User'} closeHandler={closeDeactivateModal} footerActions={deactivateModalFooterActions}>
								<LoaderOverlay showLoader={showLoader} theme="purple" size="medium" />
								<p className="user-sidebar__modal__message">
									{userData.is_active ?
										<>
											Deactivating this user means that {userData.first_name} {userData.last_name} <span>will no longer be able to log in.</span>
										</>
									:	<>
											Activating this user means that {userData.first_name} {userData.last_name} <span>will be able to log in.</span>
										</>
									}
								</p>
							</Modal>
						:	null}
					</div>
				</OwnerContextProvider>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<OwnerContextProvider userId={userData.id}>
					<div className="main-screen-section white full-height no-padding user-details-content">
						<LoaderOverlay showLoader={showLoader} />
						<header className="user-details-content__header">
							<RoleBox roles={['$user', 'admin', 'sales', 'sales_manager']}>
								<nav>
									<AppLink type="nav" to={`/user-management/${id}`} activeClassName="active" exact={true} trackClick={true} event="User Personal Details Tab Opened">
										{updatedTabs.personal_details ?
											<span className="user-details-content__header__update-indicator"></span>
										:	null}
										{erroredTabs.personal_details ?
											<span className="user-details-content__header__error-indicator"></span>
										:	null}
										Personal details
									</AppLink>
									<AppLink type="nav" to={`/user-management/${id}/expertise-regions`} activeClassName="active" trackClick={true} event="User Expertise &amp; regions Tab Opened">
										{updatedTabs.expertise_regions ?
											<span className="user-details-content__header__update-indicator"></span>
										:	null}
										Expertise &amp; regions
									</AppLink>
									<RoleBox roles={[...INTERNAL_ROLES, 'user', 'member', 'member_assistant']}>
										<AppLink type="nav" to={`/user-management/${id}/rates`} activeClassName="active" trackClick={true} event="User Rates Tab Opened">
											{updatedTabs.rates ?
												<span className="user-details-content__header__update-indicator"></span>
											:	null}
											Rates
										</AppLink>
									</RoleBox>
									<RoleBox roles={['admin', 'sales', 'sales_manager']}>
										<AppLink type="nav" to={`/user-management/${id}/points`} activeClassName="active" trackClick={true} event="User Points Tab Opened">
											{updatedTabs.points ?
												<span className="user-details-content__header__update-indicator"></span>
											:	null}
											Points
										</AppLink>
										<AppLink type="nav" to={`/user-management/${id}/preferred-associates`} activeClassName="active" trackClick={true} event="User Preferred associates Tab Opened">
											{updatedTabs.preferred_associates ?
												<span className="user-details-content__header__update-indicator"></span>
											:	null}
											Preferred associates
										</AppLink>
									</RoleBox>
									<RoleBox roles={['user']}>
										{userData.preferred_associates.length > 0 || updatedTabs.preferred_associates ?
											<AppLink
												type="nav"
												to={`/user-management/${id}/preferred-associates`}
												activeClassName="active"
												trackClick={true}
												event="User Preferred associates Tab Opened"
											>
												{updatedTabs.preferred_associates ?
													<span className="user-details-content__header__update-indicator"></span>
												:	null}
												Preferred associates
											</AppLink>
										:	null}
									</RoleBox>
									<RoleBox roles={['admin', 'sales', 'sales_manager', 'estimates', 'case_manager', 'case_manager_manager']}>
										<AppLink
											type="nav"
											to={`/user-management/${id}/blacklisted-associates`}
											activeClassName="active"
											trackClick={true}
											event="User Blacklisted associates Tab Opened"
										>
											{updatedTabs.blacklisted_associates ?
												<span className="user-details-content__header__update-indicator"></span>
											:	null}
											Blacklisted associates
										</AppLink>
									</RoleBox>
									<RoleBox roles={['user']}>
										{userData.blacklisted_associates.length > 0 || updatedTabs.blacklisted_associates ?
											<AppLink
												type="nav"
												to={`/user-management/${id}/blacklisted-associates`}
												activeClassName="active"
												trackClick={true}
												event="User Blacklisted associates Tab Opened"
											>
												{updatedTabs.blacklisted_associates ?
													<span className="user-details-content__header__update-indicator"></span>
												:	null}
												Blacklisted associates
											</AppLink>
										:	null}
									</RoleBox>
									<RoleBox roles={[...INTERNAL_ROLES, 'user', 'member', 'member_assistant']}>
										<AppLink type="nav" to={`/user-management/${id}/communication-preferences`} activeClassName="active" trackClick={true} event="User Comm.Preferences Tab Opened">
											{updatedTabs.communication_preferences ?
												<span className="user-details-content__header__update-indicator"></span>
											:	null}
											Comm.Preferences
										</AppLink>
									</RoleBox>
								</nav>
							</RoleBox>
						</header>
						<div className="user-details-content__body">
							<AppRoute route={Routes.USER_DETAILS_MAIN} exact userData={userData} onChange={onMainDataChange} errors={mainDataErrors} />
							<AppRoute route={Routes.USER_DETAILS_EXPERTISE_REGIONS} exact userData={userData} onChangeExpertise={onExpertiseChange} onChangeRegions={onChangeRegions} firmID={firmID} />
							<AppRoute route={Routes.USER_DETAILS_RATES} exact userData={userData} onChangeRate={onChangeRate} />
							<AppRoute route={Routes.USER_DETAILS_POINTS} data={userData} onChangePoints={onChangePoints} onUpdate={updateUIPointsTable} />
							<AppRoute route={Routes.USER_DETAILS_PREFERRED_ASSOCIATES} exact data={userData} onChangePreferredAssociates={onChangePreferredAssociates} />
							<AppRoute route={Routes.USER_DETAILS_BLACKLISTED_ASSOCIATES} exact data={userData} onChangeBlacklistedAssociates={onChangeBlacklistedAssociates} />
							<AppRoute
								route={Routes.USER_DETAILS_COMMUNICATION_PREFERENCES}
								exact
								data={userData}
								onChangeCommunicationPreferences={onChangeCommunicationPreferences}
								setUserData={setUserData}
							/>
						</div>
						<footer className="user-details-content__footer">
							<Checkbox
								labelClassName="user-details-content__footer__checkbox-label"
								label={
									<>
										I Agree to the{' '}
										<AppLink type="nav" to={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS.path} target="_blank">
											Terms &amp; Conditions
										</AppLink>{' '}
										and{' '}
										<AppLink type="nav" to={Routes.LEGAL_INFO_ENGAGEMENT_LETTER.path} target="_blank">
											Engagement Letter
										</AppLink>{' '}
										<RoleBox roles={['saas_user']}>of iPeer</RoleBox>
										<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r != 'saas_user')]}>of Azami Global</RoleBox>
										<span>
											{!!userData.tos_agreement_date ? ' (' : ''}
											<AppDate>{userData.tos_agreement_date}</AppDate>
											{!!userData.tos_agreement_date ? ')' : ''}
										</span>
									</>
								}
								name="tos_agreement"
								disabled={true}
								// onClick={onTOSChange}
								checked={userData.tos_agreement}
								// onChange={onTOSChange}
								title="Terms and Conditions agreement"
							/>
							<Button disabled={showSpinner} onClick={saveUserDetails} showSpinner={showSpinner}>
								Save
							</Button>
						</footer>
					</div>
				</OwnerContextProvider>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { UserDetailsMain, UserDetailsExpertiseRegions, UserDetailsRates, UserDetailsPoints, UserDetailsPreferredAssociates, UserDetailsBlacklistedAssociates, UserDetailsCommunicationPreferences };

export default UserDetails;

const init = {
	isUpdated: false,
};

//constants
const SET_IS_UPDATED = 'SET_IS_UPDATED';

//actions

export const setIsUpdated = (data) => {
	return {
		type: SET_IS_UPDATED,
		payload: data,
	};
};

//reducer
const reducer = (state = init, action) => {
	switch (action.type) {
		case SET_IS_UPDATED:
			return { ...state, isUpdated: action.payload };
		default:
			return state;
	}
};

export default reducer;

import React, { useState, useEffect } from 'react';

import Input from 'com/ui/Input';
import Select from 'com/ui/Select';
import Switch from 'com/ui/Switch';
import Button from 'com/ui/Button';
import TagSelect from 'com/ui/TagSelect';
import Textarea from 'com/ui/Textarea';

import Modal from 'com/widgets/Modal';
import RatesList from 'com/widgets/RatesList';
import CopyToClipboard from 'com/widgets/CopyToClipboard';

import Settings from 'services/rest/settings';
import FirmFamilies from 'services/rest/firm_families';
import Users from 'services/rest/users';
import PricingLevel from 'services/rest/pricing_levels';
import Firms from 'services/rest/firms';

import { RoleBox } from 'com/util/RoleBox';
import { EXTERNAL_ROLES, FIRM_TAGS, INTERNAL_ROLES } from 'data/constants';

import ICON_INFO from 'assets/images/icons/ico_info_grey.svg';
import { useSelector } from 'react-redux';

const FirmDetailsMain = (props) => {
	const firmCodeInfoIcon = {
		icon: ICON_INFO,
		tooltip: 'Share this code with colleagues to add themselves to your firm during the new user registration process.',
	};

	const initAccountsExecutives = [];

	const [regionName, setRegionName] = useState('');
	const [regions, setRegions] = useState([]);
	const [showFirmFamilyModal, setShowFirmFamilyModal] = useState(false);
	const [firmSize, setFirmSize] = useState('');
	const [firmFamilies, setFirmFamilies] = useState([]);
	const [familySearch, setFamilySearch] = useState('');
	const [firmSizes, setFirmSizes] = useState([]);
	const [accountsExecutives, setAccountsExecutives] = useState(initAccountsExecutives);
	const [pricingLevels, setPricingLevels] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [countries, setCountries] = useState([]);
	const [firmCodeIcons, setFirmCodeIcons] = useState([firmCodeInfoIcon]);
	const user = useSelector((state) => state.auth.user);

	const statuses = [
		{ label: 'Active', value: 'true' },
		{ label: 'Inactive', value: 'false' },
	];
	const types = [
		{ label: '', value: '' },
		{ label: 'Both', value: 'BOTH' },
		{ label: 'Client', value: 'CLIENT' },
		{ label: 'Associate', value: 'ASSOCIATE' },
	];
	const relationships = [
		{ label: 'Centralized', value: 'Centralized' },
		{ label: 'Decentralized', value: 'Decentralized' },
	];

	const copyFirmCodeLink = `To join me on iPeer, click this link: <a href=${window.location.origin}/register?firm-code=${props.data.code}>${window.location.origin}/register?firm-code=${props.data.code}</a>`;
	const copyFirmCodeEmailPayload = {
		registerLink: `${window.location.origin}/register?firm-code=${props.data.code}`,
	};

	useEffect(() => {
		(async () => {
			try {
				const [accountExecutives, firmSizes, firmFamilies, pricingLevels, currencies, countries] = await Promise.all([
					getSalesUsers(),
					Settings.GetFirmSizes(),
					FirmFamilies.GetAll(),
					PricingLevel.GetAll({ show_deleted: '0' }),
					Settings.GetExchangeRates(),
					Settings.GetRegions({ is_country: 1 }),
				]);

				setAccountsExecutives([
					{ label: '', value: '' },
					...accountExecutives.map((u) => ({
						label: `${u.first_name} ${u.last_name}`,
						value: u.id,
					})),
				]);
				setFirmSizes(firmSizes);
				setFirmFamilies(firmFamilies);
				setPricingLevels([
					...pricingLevels.map((p) => ({
						label: `${p.name}`,
						value: p.id,
					})),
				]);
				setCurrencies([
					{ label: '', value: '' },
					...currencies.map((c) => ({
						label: c.code,
						value: c.currency_id,
					})),
				]);
				setCountries([
					{ label: '', value: '' },
					...countries.map((c) => ({
						label: c.name,
						value: c.code,
					})),
				]);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		firmSizeChange();
	}, [props.data.number_of_attorneys, firmSizes]);

	const getSalesUsers = async () => {
		let roles = await Settings.GetUserRoles();
		if (!roles) return [];
		let salesRole = roles.find((r) => r.name == 'sales');
		let filters = {
			role: salesRole.id,
			order_by: ['first_name:asc'].join(','),
		};
		let users = await Users.GetAllRaw(filters);
		return users;
	};

	const firmSizeChange = () => {
		for (let fs of firmSizes) {
			if (fs.min && props.data.number_of_attorneys >= fs.min && ((fs.max && props.data.number_of_attorneys <= fs.max) || !fs.max)) {
				setFirmSize(fs.label);
				break;
			}
		}
	};

	const showFirmFamilyModalHandler = () => {
		setShowFirmFamilyModal(true);
	};

	const closeFirmFamilyModal = () => {
		setShowFirmFamilyModal(false);
	};

	const setFirmFamilyHandler = (e) => {
		let data = {
			target: {
				name: 'family_id',
				value: {
					firm_family_name: e.target.dataset.name,
					family_id: e.target.dataset.id,
				},
			},
		};
		props.onChange(data);
		setShowFirmFamilyModal(false);
	};

	const firmFamilyModalFooterActions = [{ primary: false, label: 'Close', action: closeFirmFamilyModal, theme: 'azami-ghost' }];

	const familySearchChange = (e) => {
		setFamilySearch(e.target.value);
	};

	return (
		<RoleBox roles={['$firm', 'admin', 'sales', 'sales_manager', '$clientOfFirm', '$agentOfFirm']}>
			<div className="firm-details-main">
				<div className="firm-details-main__left">
					<div className="firm-details-main__row">
						<div className="firm-details-main__col">
							<h3 className="firm-details-main__col__title">Basic Information</h3>
							<div className="firm-details-main__col__input-list">
								<Input
									forRoles={['admin', 'sales', 'sales_manager']}
									type="text"
									label="Name"
									onChange={props.onChange}
									value={props.data.name}
									name="name"
									error={props.errors.name}
								/>
								<RoleBox roles={['admin']}>
									<Input disabled={true} type="text" label="Email" onChange={props.onChange} value={props.data.email} name="email" error={props.errors.email} />
								</RoleBox>
								<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user'), '$firm']}>
									<CopyToClipboard
										copyContent={copyFirmCodeLink}
										copyContentLabel="copy code"
										withEmailOption={true}
										emailPayload={copyFirmCodeEmailPayload}
										emailHandler={Firms.SendRegisterEmailWithCode}
										forRoles={['admin', 'saas_user']}
									>
										<Input disabled={true} type="text" label="Code" onChange={props.onChange} value={props.data.code} name="code" error={props.errors.code} icons={firmCodeIcons} />
									</CopyToClipboard>
								</RoleBox>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="Website"
									onChange={props.onChange}
									value={props.data.website}
									name="website"
									error={props.errors.website}
								/>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="number"
									min="1"
									max="50000"
									step="1"
									value={props.data.number_of_attorneys}
									customClassName="number_of_attorneys"
									name="number_of_attorneys"
									label="Number of Attorneys"
									onChange={props.onChange}
									error={props.errors.number_of_attorneys}
								/>
								<RoleBox roles={['admin', 'sales', 'sales_manager']}>
									<div id="firm_size_container">
										<div className="form-input__label firm_size_label light">Firm Size</div>
										<div className="form-input__label firm_size_text">{firmSize}</div>
									</div>
								</RoleBox>
								<RoleBox roles={['admin', 'sales', 'sales_manager']}>
									<Input
										type="number"
										min="1800"
										max="50000"
										step="1"
										value={props.data.year_started}
										onChange={props.onChange}
										label="Year Firm Started"
										name="year_started"
										error={props.errors.year_started}
									/>
									<Select options={statuses} label="Status" onChange={props.onChange} value={props.data.is_active} name="is_active" theme="light" />
								</RoleBox>
								<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
									<Select
										forRoles={['admin', 'sales', 'sales_manager']}
										options={accountsExecutives}
										label="Account Executive"
										onChange={props.onChange}
										value={props.data.account_executive_id}
										name="account_executive_id"
									/>
								</RoleBox>
								<RoleBox roles={['admin', 'sales', 'sales_manager']}>
									<TagSelect label="Tags" options={FIRM_TAGS} values={props.data.tags} onChange={props.onChange} />
								</RoleBox>
							</div>
						</div>
						<div className="firm-details-main__col">
							<h3 className="firm-details-main__col__title">Firm Address</h3>
							<div className="firm-details-main__col__input-list">
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="Address"
									onChange={props.onChange}
									value={props.data.address}
									name="address"
									error={props.errors.address}
								/>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="Address 2"
									onChange={props.onChange}
									value={props.data.address2}
									name="address2"
									error={props.errors.address}
								/>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="City"
									onChange={props.onChange}
									value={props.data.city}
									name="city"
									error={props.errors.city}
								/>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="State"
									onChange={props.onChange}
									value={props.data.state}
									name="state"
									error={props.errors.state}
								/>
								<Select
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									options={countries}
									label="Country"
									onChange={props.onChange}
									value={props.data.country}
									name="country"
									theme="light"
								/>
								<Input
									forRoles={['admin', 'sales', 'sales_manager', '$firm']}
									type="text"
									label="Postal code"
									onChange={props.onChange}
									value={props.data.zipcode}
									name="zipcode"
									error={props.errors.zipcode}
								/>
								<RoleBox roles={['saas_user']}>
									<Select
										options={currencies}
										label="Preferred Currency"
										onChange={props.onChange}
										name="currency_id"
										value={props.data.currency_id}
										error={props.errors.currency_id}
										disabled={user.fid !== props.data.id ? true : false}
									/>
								</RoleBox>
							</div>
						</div>
					</div>
					<div className="firm-details-main__row">
						<RoleBox roles={INTERNAL_ROLES}>
							<div className="firm-details-main__col">
								<Textarea name="notes" label="Notes" onChange={props.onChange} value={props.data.notes} disabled={true} />
							</div>
						</RoleBox>
					</div>
				</div>
				<RoleBox roles={INTERNAL_ROLES}>
					<div className="firm-details-main__right">
						<div className="firm-details-main__row">
							<div className="firm-details-main__col">
								<h3 className="firm-details-main__col__title">Additional Information</h3>
								<div className="firm-details-main__col__input-list">
									<Select
										forRoles={['admin', 'sales', 'sales_manager']}
										options={types}
										label="Type"
										onChange={props.onChange}
										value={props.data.type}
										name="type"
										error={props.errors.type}
									/>
									<Switch onChange={props.onChange} value={props.data.is_vendor} label="Non Recip Vendor" name="is_vendor" className="top_100" />
									<Switch onChange={props.onChange} value={props.data.is_non_recip_client} label="Non Recip Client" name="is_non_recip_client" className="top_100" />
								</div>
								<div className="firm-details-main__col__input-list">
									<Switch onChange={props.onChange} value={props.data.is_top_100} label="Top 100 Firm" name="is_top_100" className="top_100" />
									<Switch onChange={props.onChange} value={props.data.is_centralized} label="Firm is centralized" name="is_centralized" className="top_100" />
								</div>
								<Select options={currencies} label="Currency" onChange={props.onChange} name="currency_id" value={props.data.currency_id} error={props.errors.currency_id} />
								<h3 className="firm-details-main__col__title">Firm Family</h3>
								<div className="firm-details-main__col__input-list vcenter">
									{props.data.firm_family_name ?
										<span className="firm-family">{props.data.firm_family_name}</span>
									:	<span className="firm-family-missing">Not Set</span>}
									<Button theme="lightblue" onClick={showFirmFamilyModalHandler}>
										Assign Firm Family
									</Button>
									{showFirmFamilyModal && (
										<Modal title="Firm Families" closeHandler={closeFirmFamilyModal} footerActions={firmFamilyModalFooterActions}>
											<div>
												<Input type="text" onChange={familySearchChange} value={familySearch} placeholder="Search Firms" />
												<ul className="firm-families-popup-list">
													{firmFamilies
														.filter((f) => {
															if (familySearch === '') return true;
															return f.name.toLowerCase().includes(familySearch.toLowerCase());
														})
														.map((f) => {
															return (
																<li data-name={f.name} data-id={f.id} key={f.id} onClick={setFirmFamilyHandler} className="firm-families-popup-list__item">
																	{f.name}
																</li>
															);
														})}
												</ul>
											</div>
										</Modal>
									)}
								</div>
								<h3 className="firm-details-main__col__title">Firm Flags</h3>
								<div className="firm-details-main__col__input-list">
									<Switch onChange={props.onChange} value={props.data.is_owed_case} label="Firm Owed Case" name="is_owed_case" className="owed_case" />
								</div>
								<h3 className="firm-details-main__col__title">Pricing Levels</h3>
								<div>
									<div className="firm-details-main__col__input-list">
										<div className="form-input__label pricing_levels_label light">PCT/Paris Convention</div>
										<Select
											options={pricingLevels}
											label="Professional Fee"
											onChange={props.onChange}
											value={props.data.pricing_level_id}
											name="pricing_level_id"
											error={props.errors.pricing_level_id}
											customClassName="pricing-levels"
										/>
										<Select
											options={pricingLevels}
											label="Translation Fee"
											onChange={props.onChange}
											value={props.data.pricing_level_translation_id}
											name="pricing_level_translation_id"
											error={props.errors.pricing_level_translation_id}
											customClassName="pricing-levels"
										/>
										<div className="form-input__label pricing_levels_label light">European Validation</div>
										<Select
											options={pricingLevels}
											label="Professional Fee"
											onChange={props.onChange}
											value={props.data.pricing_level_validations_id}
											name="pricing_level_validations_id"
											error={props.errors.pricing_level_validations_id}
											customClassName="pricing-levels"
										/>
										<Select
											options={pricingLevels}
											label="Translation Fee"
											onChange={props.onChange}
											value={props.data.pricing_level_validations_translation_id}
											name="pricing_level_validations_translation_id"
											error={props.errors.pricing_level_validations_translation_id}
											customClassName="pricing-levels"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</RoleBox>
			</div>
		</RoleBox>
	);
};

export default FirmDetailsMain;

import LOGO_SMALL_AZAMI from 'assets/images/azami_logo.svg';
import LOGO_SMALL_IPEER from 'assets/images/ipeer_logo.svg';

const initAzami = {
	logoSmall: LOGO_SMALL_AZAMI,
	fullName: 'AzamiGlobal',
	fullNameSpaced: 'Azami Global',
	shortName: 'Azami',
	header: {
		quotes: 'Quotes',
	},
};

const initIpeer = {
	logoSmall: LOGO_SMALL_IPEER,
	fullName: 'iPeer',
	fullNameSpaced: 'iPeer',
	shortName: 'iPeer',
	header: {
		quotes: 'Saved Quotes',
	},
};

// constants
const SET_BRANDING_AZAMI = 'SET_BRANDING_AZAMI';
const SET_BRANDING_IPEER = 'SET_BRANDING_IPEER';

// actions
export const setBrandingAzami = () => {
	return {
		type: SET_BRANDING_AZAMI,
	};
};

export const setBrandingIpeer = () => {
	return {
		type: SET_BRANDING_IPEER,
	};
};

// reducer
const reducer = (state = setBrandingAzami, action) => {
	switch (action.type) {
		case SET_BRANDING_AZAMI:
			return { ...initAzami };
		case SET_BRANDING_IPEER:
			return { ...initIpeer };
		default:
			return state;
	}
};

export default reducer;

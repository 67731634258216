export const API_HOST = `${process.env.REACT_APP_API_URL}/api/v1`;
export const DEFAULT_ERROR = 'DEFAULT_ERROR';
export const INVALID_DATA_ERROR = 'INVALID_DATA_ERROR';
export const SERVER_ERROR = 'SERVER_ERROR';
export const FORBIDDEN_ERROR = 'FORBIDDEN_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE';
export const UNAUTHORISED = 'UNAUTHORISED';
export const RENEWALS_URL = 'https://azami.my.site.com/s/';
export const PATENT_SEARCHES_URL = 'https://azamiglobal.com/patent-searches/';
export const REGION_KNOWLEDGE_URL = 'https://help.azamiglobal.com/knowledge-base/region-information';

export const TRANSLATIONS_REQUIREMENT_TYPES = {
	REQUIRED: 'REQUIRED',
	NOT_REQUIRED: 'NOT_REQUIRED',
	CLAIMS: 'CLAIMS',
	FULL_TRANSLATION: 'FULL_TRANSLATION',
	CLAIMS_ENG_DESC: 'CLAIMS_ENG_DESC',
	NA: 'NA',
};

export const POINTS_TRANSACTION_TYPE = {
	CASE: 'CASE',
	ACCOUNT: 'ACCOUNT',
};

export const POINTS_TRANSCATION_DIRECTION = {
	CLIENT: 'CLIENT',
	ASSOCIATE: 'ASSOCIATE',
};

export const FIRM_TAGS = [
	{ label: '', value: '' },
	{ label: 'Portfolio', value: 'PORTFOLIO' },
	{ label: 'VIP', value: 'VIP' },
	{ label: 'Blocked', value: 'BLOCKED' },
	{ label: 'DNOR', value: 'DNOR' },
];

export const RECIPROCITY_OVERRIDE_TYPE = {
	SYSTEM: 'SYSTEM',
	FIRM: 'FIRM',
	ACCOUNT: 'ACCOUNT',
	USER: 'USER',
};

export const RECIPROCITY_OVERRIDE_DIRECTION = {
	INCOMING: 'INCOMING',
	OUTGOING: 'OUTGOING',
};

export const ACCOUNT_CONFIMATION_MESSAGES = {
	success: `You successfully confirmed your Azami Global account. Please proceed to the login page and enter the portal.`,
	fail: `Something went wrong with your account confirmation. Please try opening the confirmation link sent to you via email again. If the problem persists, contact us on contact@azamiglobal.com`,
};

export const COMMUNICATION_PREFERENCES = {
	SEND_DOCUMENTS: {
		title: 'Documents',
		subtitle: 'Send me documents',
		name: 'SEND_DOCUMENTS',
		field_type: 'radio',
		value_type: 'string',
		options: ['COMBINED_REGIONS', 'PER_REGION'],
		labels: ['with combined regions', 'per region'],
		group: 'DOCUMENTS_SETTINGS',
	},
	SEND_EMAILS: {
		title: 'Emails',
		subtitle: 'Send me emails',
		name: 'SEND_EMAILS',
		field_type: 'radio',
		value_type: 'string',
		options: ['COMBINED_REGIONS', 'PER_REGION'],
		labels: ['with combined regions', 'per region'],
		group: 'EMAIL_SETTINGS',
	},
	AUTO_APPROVE_ASSOCIATE: {
		title: 'Approve Associate',
		subtitle: '',
		name: 'AUTO_APPROVE_ASSOCIATE',
		field_type: 'switch',
		value_type: 'boolean',
		options: ['AUTOMATICALLY'],
		labels: ['Automatically'],
		group: 'GENERAL_SETTINGS',
	},
};

export const FILE_CATEGORY = [
	{ label: 'Select Category', value: '' },
	{ label: 'Initial Documents', value: 'INITIAL_DOCUMENTS' },
	{ label: 'Documents for Execution', value: 'DOCUMENTS_FOR_EXECUTION' },
	{ label: 'Executed Docs/Docs for Filing', value: 'EXECUTED_DOCS/DOCS_FOR_FILING' },
	{ label: 'Filing Reports', value: 'FILING_REPORTS' },
	{ label: 'Official Filing Receipt', value: 'OFFICIAL_FILING_RECEIPT' },
	{ label: 'Translation of Specification', value: 'TRANSLATION_OF_SPECIFICATION' },
	{ label: 'Bill from Foreign Associate', value: 'BILL_FROM_FOREIGN_ASSOCIATE' },
	{ label: 'Invoice to Client', value: 'INVOICE_TO_CLIENT' },
	{ label: 'Power of Attorney (executed)', value: 'POWER_OF_ATTORNEY(EXECUTED)' },
	{ label: 'Power of Attorney (unsigned)', value: 'POWER_OF_ATTORNEY(UNSIGNED)' },
	{ label: 'Rates Confirmation', value: 'RATES_CONFIRMATION' },
	{ label: 'Other', value: 'OTHER' },
];

export const LOGIN_AZAMI = `The world's largest foreign filing network for patent and trademark attorneys, Azami Global brings together the top firms in the world to provide the best IP filing services for any region around the globe.`;
export const QUOTE_LIST_FIELDS = {
	ENTITY_SIZE: 'ENTITY_SIZE',
	LOCATION_OF_SEARCH: 'LOCATION_OF_SEARCH',
	APPLICATION_LANGUAGE: 'APPLICATION_LANGUAGE',
	UTILITY_MODEL_PRIORITY: 'UTILITY_MODEL_PRIORITY',
};
export const QUOTES_ENTITY_SIZE = [
	{ value: 'LARGE', label: 'Large' },
	{ value: 'SMALL', label: 'Small' },
	{ value: 'INDIVIDUAL', label: 'Micro/Individual' },
];

export const EXTERNAL_ROLES = ['user', 'member', 'member_assistant', 'saas_user'];
export const INTERNAL_ROLES = ['admin', 'sales', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales_manager'];
export const ROLE_TYPES = {
	INTERNAL: 'INTERNAL',
	EXTERNAL: 'EXTERNAL',
};
export const USER_ROLES = {
	ADMIN: 'admin',
	MEMBER: 'member',
	USER: 'user',
	MEMBER_ASSISTANT: 'member_assistant',
	SALES: 'sales',
	CASE_MANAGER_MANAGER: 'case_manager_manager',
	CASE_MANAGER: 'case_manager',
	ESTIMATES: 'estimates',
	FINANCE: 'finance',
	SALES_MANAGER: 'sales_manager',
	SAAS_USER: 'saas_user',
};

export const UTILITY_MODEL_PRIORITY_TYPE = {
	PCT_NPE: 'PCT_NPE',
	PARIS_CONVENTION: 'PARIS_CONVENTION',
};

export const RECORDAL_TYPES = [
	{ label: 'Change of Name', value: 'CHANGE_OF_NAME' },
	{ label: 'Change of Address', value: 'CHANGE_OF_ADDRESS' },
	{ label: 'Assignment of Rights', value: 'ASSIGNMENT_OF_RIGHTS' },
	{ label: 'Transfer of Ownership', value: 'TRANSFER_OF_OWNERSHIP' },
];

export const SERVICE_KEYS = {
	TRANSLATIONS: 'TRANSLATIONS',
	OTHER: 'OTHER',
	UNITARY: 'UNITARY',
	UTILITY: 'UTILITY',
	PROSECUTION: 'PROSECUTION',
	PCT: 'PCT',
	RECORDAL: 'RECORDAL',
	DESIGN: 'DESIGN',
	EP: 'EP',
	TRADEMARKS: 'TRADEMARKS',
	PARIS: 'PARIS',
	PCT_IP: 'PCT_IP',
	ASSIGNMENTS: 'ASSIGNMENTS',
};

export const ESTIMATOR_RULE_LEVELS = [
	{ value: '', label: 'All' },
	{ value: 'SYSTEM', label: 'System' },
	{ value: 'FIRM', label: 'Firm' },
	{ value: 'CASE', label: 'Case' },
];

export const RULE_LEVEL = {
	SYSTEM: 'SYSTEM',
	FIRM: 'FIRM',
	CASE: 'CASE',
};

export const CaseRegionStatus = {
	INSTRUCTED: 'INSTRUCTED',
	UNINSTRUCTED: 'UNINSTRUCTED',
	APPROVED: 'APPROVED',
	FILED: 'FILED',
	NEEDS_ATTENTION: 'NEEDS_ATTENTION',
	INITIAL_CONFIRM: 'INITIAL_CONFIRM',
	FINAL_CONFIRM: 'FINAL_CONFIRM',
	HOLD: 'HOLD',
	COMPLETED: 'COMPLETED',
	PO_CLOSURE: 'PO_CLOSURE',
	CANCELED: 'CANCELED',
};

export const CASES_REGIONS_STATUSES = {
	INITIAL_CONFIRMATION_RECEIVED: {
		order: 6,
		status: 'INITIAL_CONFIRMATION_RECEIVED',
	},
	INSTRUCTIONS_RECEIVED_BY_ASSOCIATE: {
		order: 3,
		status: 'INSTRUCTIONS_RECEIVED_BY_ASSOCIATE',
	},
};
export const CASE_DIRECTIONS = {
	SENT: 'SENT',
	RECEIVED: 'RECEIVED',
};

export const CASE_DIRECTIONS_OPTIONS = [
	{ label: 'ALL', value: 'ALL' },
	{ label: 'SENT', value: 'SENT' },
	{ label: 'RECEIVED', value: 'RECEIVED' },
];

export const SELECTED_ASSOCIATE_STATUS = {
	NEW: 'NEW',
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	UNAPPROVED: 'UNAPPROVED',
};

export const DOCUMENT_OBJECT_TYPE = {
	QUOTE_REGION: 'QUOTE_REGION',
	QUOTE: 'QUOTE',
	CASE_REGION: 'CASE_REGION',
	CASE: 'CASE',
	CLIENT_ONBOARD_DATA: 'CLIENT_ONBOARD_DATA',
	AGENT_INVOICE: 'AGENT_INVOICE',
};

export const DOCUMENT_OBJECT_CATEGORY = {
	CLIENT_ONBOARD_DATA_CATEGORY: 'CLIENT_ONBOARD_DATA_CATEGORY',
	AGENT_INVOICE_CATEGORY: 'AGENT_INVOICE_CATEGORY',
};

export const MILESTONES = [
	{ name: 'Instructions Received by Azami', value: 'INSTRUCTIONS_RECEIVED_BY_AZAMI', order: 1 },
	{ name: 'Instructions Sent to Foreign Associate', value: 'INSTRUCTIONS_SENT_TO_FOREIGN_ASSOCIATE', order: 2 },
	{ name: 'Case Placed with Foreign Associate', value: 'CASE_PLACED_WITH_FOREIGN_ASSOCIATE', order: 3 },
	{ name: 'Documents for Execution Sent to Member', value: 'DOCUMENTS_FOR_EXECUTION_SENT_TO_MEMBER', order: 4 },
	{ name: 'Executed Documents Sent to Foreign Associate', value: 'EXECUTED_DOCUMENTS_SENT_TO_FOREIGN_ASSOCIATE', order: 5 },
	{ name: 'Informal Confirmation Received from Foreign Associate', value: 'INFORMAL_CONFIRMATION_RECEIVED_FROM_FOREIGN_ASSOCIATE', order: 6 },
	{ name: 'Official Confirmation Received', value: 'OFFICIAL_CONFIRMATION_RECEIVED', order: 7 },
];

export const STATUS_COLORS = {
	INSTRUCTED: '#475c8a',
	UNINSTRUCTED: '#008f7a',
	APPROVED: '#845ec2',
	FILED: '#ffbd42',
	NEEDS_ATTENTION: '#aca9bb',
	INITIAL_CONFIRM: '#415bf7',
	FINAL_CONFIRM: '#ffbd42',
	HOLD: '#ae5d00',
	COMPLETED: '#26f451',
	PO_CLOSURE: '#f42626',
	CANCELED: '#008f7a',
};
export const EUROPEAN_VALIDATION_EXT_REGIONS = ['BA', 'KH', 'ME', 'MA', 'MD', 'TN'];

export const WIPO_SERVICES = [SERVICE_KEYS.UTILITY, SERVICE_KEYS.PCT, SERVICE_KEYS.EP, SERVICE_KEYS.PARIS];

export const APP_NUM_IGNORED_KEY_WORDS = ['NONE', 'UNKNOWN', 'NOT PROVIDED', 'NONE PROVIDED', 'GENERAL ESTIMATE'];

export const ESTIMATE_STATUS = {
	DRAFT: 'DRAFT',
	PUBLISHED: 'PUBLISHED',
};

export const PAGE_TITLE = {
	BASE: 'Azami Global',
	IPEER: 'iPeer',
	DASHBOARD: 'Dashboard',
	REPORTS: 'Reports',
	MEMBER_REPORT: 'Member Report',
	CASE_REPORT: 'Case Report',
	REGIONAL_REPORT: 'Regional Report',
	TOP_10_REPORT: 'Top 10 Report',
	OWED_ASSOCIATES_REPORT: 'Owed Associates Report',
	ASSOCIATE_ASSIGNMENT: 'Associate Assignment',
	USER_MANAGEMENT: 'User Management',
	USER_DETAILS: 'User Details',
	USER_CREATE: 'Create User',
	FIRM_MANAGEMENT: 'Firm Management',
	FIRM_DETAILS: 'Firm Details',
	FIRM_CREATE: 'Create Firm',
	TOOLS: 'Tools',
	ASSIGNMENT_OVERRIDE_REQUESTS: 'Assignment Override Requests',
	TRANSLATION_REQUESTS: 'Translation Requests',
	DUPLICATE_MANAGEMENT: 'Duplicate Management',
	CASES: 'Cases',
	CASE_DETAILS: 'Case Details',
	QUOTES: 'Quotes',
	QUOTE_DETAILS: 'Quote Details',
	REQUEST_QUOTE: 'Request Quote',
	EDIT_QUOTE: 'Edit Quote',
	SYSTEM_SETTINGS: 'System Settings',
	LOGIN: 'Login',
	REGISTER: 'Register',
	FORGOT_PASSWORD: 'Forgot Password',
	AGENTS: 'My Agents',
	RESET_PASSWORD: 'Reset Password',
};

export const ISSUE_TYPES = [
	{ label: '', value: '' },
	{ label: 'Login/Password', value: 'LOGIN_PASSWORD' },
	{ label: 'Quotes', value: 'QUOTES' },
	{ label: 'Cases/Instructions', value: 'CASES_INSTRUCTIONS' },
	{ label: 'Technical Support', value: 'TECHNICAL_SUPPORT' },
	{ label: 'Other', value: 'OTHER' },
];

export const PREFERENCES_TYPE = {
	USER: 'USER',
	FIRM: 'FIRM',
	SYSTEM: 'SYSTEM',
};

export const PREFERENCES_GROUP = {
	GENERAL_SETTINGS: 'GENERAL_SETTINGS',
	EMAIL_SETTINGS: 'EMAIL_SETTINGS',
	DOCUMENTS_SETTINGS: 'DOCUMENTS_SETTINGS',
};

export const PREFERENCES_KEYS = {
	SEND_EMAILS: 'SEND_EMAILS',
	INTAKE: 'INTAKE',
	DEFAULT_SUPPORT_EMAIL_ADDRESSES: 'DEFAULT_SUPPORT_EMAIL_ADDRESSES',
	DEFAULT_ACCOUNT_EXECUTIVE: 'DEFAULT_ACCOUNT_EXECUTIVE',
	EXCHANGE_RATE_PADDING: 'EXCHANGE_RATE_PADDING',
};

export const QUOTES_ENTITY_SIZE_VALUES = {
	large: 'LARGE',
	small: 'SMALL',
	individual: 'INDIVIDUAL',
};

export const DEADLINE_LABELS = {
	DESIGN: '6-month Deadline:',
	EP: 'Validation Deadline:',
	TRADEMARKS: '6-month Deadline:',
	PARIS: '12-month Deadline:',
	PCT: '30-month Deadline:',
	UNITARY: 'U.P. Deadline:',
};

export const QUOTE_EVENTS = {
	REQUEST_QUOTE: 'Request Quote',
	IWOQ: 'Instruct Without Pre-Existing Quote',
};

export const ENVIRONMENTS = {
	PROD: 'production',
	DEV: 'development',
};

export const QUOTE_STATUS = {
	DRAFT: 'DRAFT',
	PENDING: 'PENDING',
	PUBLISHED: 'PUBLISHED',
};

export const QUOTE_VALIDATION_ERROR = {
	MISSING_VALUE: 'A value in one or more rules is missing.',
	NEVER_CALCULATE_QUOTE_AUTOMATICALLY: 'A stop rule exists.',
	EP_WORD_COUNT_MISSING: 'One or more word count fields equals 0. ',
	DESCRIPTION_IMAGES_SIZE: 'There is text in images in the description on WIPO.',
	LOW_NUMBER_OF_WORDS: 'The total number of words appears to be too low.',
};

export const WIPO_IMAGES_SIZE_THRESHOLD = 13500;

export const ASSOCIATE_TYPE = {
	IN: 'IN',
	OON: 'OON',
};

export const FIRM_TYPE = {
	BOTH: 'BOTH',
	CLIENT: 'CLIENT',
	ASSOCIATE: 'ASSOCIATE',
};
//////////////////// renewals ////////////////////

export const IP_TYPE_OPTIONS = [
	{ label: 'All', value: 'ALL' },
	{ label: 'Patent', value: 'PATENT' },
	{ label: 'Design', value: 'DESIGN' },
	{ label: 'Utility Model', value: 'UTILITY_MODEL' },
];

export const VALIDATION_RULE_TYPES = {
	PREFIX: 'PREFIX',
	PREFIX_AND_DIGITS: 'PREFIX_AND_DIGITS',
	PREFIX_SEPARATOR_DIGITS: 'PREFIX_SEPARATOR_DIGITS',
	PREFIX_DIGITS_STARTS_WITH: 'PREFIX_DIGITS_STARTS_WITH',
	PREFIX_DIGITS_SUFFIX: 'PREFIX_DIGITS_SUFFIX',
	PREFIX_SUFFIX_WITH_SEPARATOR: 'PREFIX_SUFFIX_WITH_SEPARATOR',
	PREFIX_DIGITS_DECIMAL: 'PREFIX_DIGITS_DECIMAL',
	DIGITS: 'DIGITS',
	DIGITS_WITH_DECIMAL: 'DIGITS_WITH_DECIMAL',
	ALERT_ONLY: 'ALERT_ONLY',
};

export const VALIDATION_RULE_LABELS = [
	{ label: '', value: '' },
	{ label: 'Prefix', value: VALIDATION_RULE_TYPES.PREFIX },
	{ label: 'Prefix And Digits', value: VALIDATION_RULE_TYPES.PREFIX_AND_DIGITS },
	{ label: 'Prefix With Separator And Digits', value: VALIDATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS },
	{ label: 'Prefix And Digit Starts With', value: VALIDATION_RULE_TYPES.PREFIX_DIGITS_STARTS_WITH },
	{ label: 'Prefix Digits Suffix', value: VALIDATION_RULE_TYPES.PREFIX_DIGITS_SUFFIX },
	{ label: 'Prefix Suffix With Separator', value: VALIDATION_RULE_TYPES.PREFIX_SUFFIX_WITH_SEPARATOR },
	{ label: 'Prefix Digit Decimal', value: VALIDATION_RULE_TYPES.PREFIX_DIGITS_DECIMAL },
	{ label: 'Digits Only', value: VALIDATION_RULE_TYPES.DIGITS },
	{ label: 'Digits With Decimal', value: VALIDATION_RULE_TYPES.DIGITS_WITH_DECIMAL },
	{ label: 'Alert Only (throws only alert message for specified region)', value: VALIDATION_RULE_TYPES.ALERT_ONLY },
];

export const VALIDATION_RULE_DESCRIPTION = {
	PREFIX: `Rule will validate for prefix before selected number. \nexample: PCT112233 will validate for PCT before 112233`,
	PREFIX_AND_DIGITS: `Rule will validate for prefix before selected number and number of digits.\nDigits field is optional, if left blank rule will validate for prefix and any number of digits after the prefix.\nexample:Prefix PCT and digits 6 - PCT112233 is valid\nexample:Prefix PCT and digits left blank - PCT11223344(any number of digits after PCT) is valid,\nexample:Prefix PCT and digits then text after the digits is not valid - PCT112233ABC - not valid,`,
	PREFIX_SEPARATOR_DIGITS: `Rule will validate for prefix before selected number with character as separator between prefix and digits, and also for number of digits.\nDigits field is optional.\nexample:Prefix PCT and separator '-' PCT-112233 is valid,\nexample:Prefix PCT and separator '-' and digits 6 PCT-112233 is valid,\nexample:Prefix PCT and separator '-' PCT-112233ABC is not valid,`,
	PREFIX_DIGITS_STARTS_WITH: `Rule will validate for prefix before selected number, and for the starting digit of the number after the prefix.\nDigits field is optional, if left blank rule will validate for any number of digits after the prefix.\nexample:Prefix EA and Digit Start With 1 - EA112233 is valid\nexample:Prefix EA and Digit Start With 1 and digits 6 - EA112233 is valid\nexample:Prefix EA and Digit Start With 1 - EA012233 is - not valid\nexample:Prefix EA and Digit Start With 1 - PCT12233ABC is - not valid`,
	PREFIX_DIGITS_SUFFIX: `Rule will validate for prefix before selected number and suffix after selected number, also for number of digits between prefix and suffix.\nDigits field is optional, if left blank rule will validate for any number of digits between prefix and suffix.\nexample:Prefix BR and Suffix A - BR112233A is valid\nexample:Prefix BR and Suffix A and Digits 6 - BR123456A is valid\nexample:Prefix BR and Suffix A BR112233 is not valid`,
	PREFIX_SUFFIX_WITH_SEPARATOR: `Rule will validate for prefix before selected number with character as separator between prefix and digits, then will validate for suffix after the digits, with character as separator between suffix and digits.\nRule can also validate for number of digits.\nDigits field is optional, if left blank rule will validate for any number of digits between the prefix-separator-digits-separator-suffix combination.\nFormat: prefix-separator-digits-separator-suffix.\nexample: Prefix: MY, Separator-After-Prefix: '-', Digits: 6, Separator-Before-Suffix: '-', Suffix: A \nMY-123456-A is valid`,
	PREFIX_DIGITS_DECIMAL: `Rule will validate for prefix before selected number, and if number after the prefix is decimal\nDigits field is optional, if left blank rule will validate for any number of digits after the prefix\nDecimals field is optional, if left blank rule will validate for any number of decimal fields after the number\nExample: Prefix: BR, Digits: 6, Decimals:2\nBR123456.02 is valid, 123456.0245 is not valid`,
	DIGITS: `Rule will validate if selected number contains only digits, and for number of digits.\nDigits field is optional, if left blank rule will validate for any number of digits\nExample: 12345678 is valid\nExample: BR12345678 is not valid`,
	DIGITS_WITH_DECIMAL: `Rule will validate if selected number is decimal. It can also validate for number of digits and decimals.\nDigits field is optional, if left blank rule will validate for any number of digits.\nDecimals field is optional, if left blank rule will validate for any number of decimal fields.\nExample: Digits 6, Decimals 2 - 123456.12 is valid\nExample: Digits 6, Decimals 2 - PR123456.12 is not valid`,
	ALERT_ONLY: 'Rule is used to display only alert log message',
};

export const VALIDATION_OBJECT_TYPE = [
	{ label: '', value: '' },
	{ label: 'Application Number', value: 'APPLICATION_NUMBER' },
	{ label: 'Patent Number', value: 'PATENT_NUMBER' },
];

export const TRANSFORMATION_RULE_TYPES = {
	PREFIX: 'PREFIX',
	PREFIX_NUMBER_REMOVE_DECIMAL: 'PREFIX_NUMBER_REMOVE_DECIMAL',
	PREFIX_NUMBER_REMOVE_DASHNUM_AT_END: 'PREFIX_NUMBER_REMOVE_DASHNUM_AT_END',
	REMOVE_PREFIX: 'REMOVE_PREFIX',
	PREFIX_DIGITS_SEPARATOR_DIGITS: 'PREFIX_DIGITS_SEPARATOR_DIGITS',
	PREFIX_SEPARATOR_DIGITS: 'PREFIX_SEPARATOR_DIGITS',
	PREFIX_OR_REMOVE_PREFIX_AND_SUFFIX: 'PREFIX_OR_REMOVE_PREFIXANDSUFFIX',
	PREFIX_SEPARATOR_DIGITS_SEPARATOR_SUFFIX: 'PREFIX_SEPARATOR_DIGITS_SEPARATOR_SUFFIX',
	DIGITS_ONLY: 'DIGITS_ONLY',
	DIGITS_DECIMAL: 'DIGITS_DECIMAL',
	PREFIX_DIGITS: 'PREFIX_DIGITS',
	NUMBER_PLAIN: 'NUMBER_PLAIN',
	ALERT_ONLY: 'ALERT_ONLY',
	COUNTRY_CODE_OR_OPTIONAL_PREFIX_FIRST: 'COUNTRY_CODE_OR_OPTIONAL_PREFIX_FIRST',
};

export const TRANSFORMATION_RULE_LABELS = [
	{ label: '', value: '' },
	{ label: 'Prefix', value: TRANSFORMATION_RULE_TYPES.PREFIX },
	{ label: 'Prefix And Remove Decimal From Number', value: TRANSFORMATION_RULE_TYPES.PREFIX_NUMBER_REMOVE_DECIMAL },
	{ label: 'Prefix And Remove -number AT END', value: TRANSFORMATION_RULE_TYPES.PREFIX_NUMBER_REMOVE_DASHNUM_AT_END },
	{ label: 'Remove Prefix', value: TRANSFORMATION_RULE_TYPES.REMOVE_PREFIX },
	{ label: 'Prefix Digits Separator Digits', value: TRANSFORMATION_RULE_TYPES.PREFIX_DIGITS_SEPARATOR_DIGITS },
	{ label: 'Prefix Separator Digits', value: TRANSFORMATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS },
	// { label: 'Prefix OR (Remove Prefix AND Suffix)', value: TRANSFORMATION_RULE_TYPES.PREFIX_OR_REMOVE_PREFIX_AND_SUFFIX },
	{ label: 'Prefix Separator Digits Separator Suffix', value: TRANSFORMATION_RULE_TYPES.PREFIX_SEPARATOR_DIGITS_SEPARATOR_SUFFIX },
	{ label: 'Digits Only', value: TRANSFORMATION_RULE_TYPES.DIGITS_ONLY },
	{ label: 'Digits With Decimal', value: TRANSFORMATION_RULE_TYPES.DIGITS_DECIMAL },
	{ label: 'Prefix Digits', value: TRANSFORMATION_RULE_TYPES.PREFIX_DIGITS },
	{ label: 'Number Plain', value: TRANSFORMATION_RULE_TYPES.NUMBER_PLAIN },
	{ label: 'Alert Only', value: TRANSFORMATION_RULE_TYPES.ALERT_ONLY },
	{ label: 'Country Code or optional prefix first', value: TRANSFORMATION_RULE_TYPES.COUNTRY_CODE_OR_OPTIONAL_PREFIX_FIRST },
];

export const TRANSFORMATION_RULE_DESCRIPTION = {
	PREFIX: `Rule will remove non-digit characters that are in front of selected number, and add provided prefix in front of it.\nFor Option - for what number rule should be applied(selected number).\nBase Option - transformation will be done based on this provided value.\nexample: prefix:AU, number:112233 - AU112233\nexample: prefix:AU, number:NUM112233 - AU112233.`,
	PREFIX_NUMBER_REMOVE_DECIMAL: `Rule will add prefix in front of selected number, then will remove decimals at the end and optionally replace them with provided value.\nFor Option - for what number rule should be applied(selected number).\nBase Option - transformation will be done based on this provided value.\nReplace at end with - optional value with which decimal at the end will be replaced.\nIf Replace at end with is not provided, only the decimal at the end will be removed.\nExample: PREFIX:CN, Replace AT End With: A, provided number - 202110417242.0 - transforms to CN202110417242A`,
	PREFIX_NUMBER_REMOVE_DASHNUM_AT_END: `Rule will add prefix in front of selected number and remove '-number' pattern at the end.\nFor Option - for what number rule should be applied(selected number).\nBase Option - transformation will be done based on this provided value.\nExample: Prefix CN, number CN123456789-12 transforms to CN123456789`,
	REMOVE_PREFIX: `Rule will remove all non digit characters in front of selected number\nFor Option - for what number rule should be applied(selected number).\nBase Option - transformation will be done based on this provided value.\nExample PCT123456 transforms to 123456`,
	PREFIX_DIGITS_SEPARATOR_DIGITS: `Rule will optionally add prefix in front of selected number, then it will apply number of the digits from the provided Digits Front, and digits from the back from provided Digits back, and will put character separator between them.\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.\nExample:  Separator '-', Digits Front:4, Digits back:6,\nJP2014516100A transforms to  2014-516100`,
	PREFIX_SEPARATOR_DIGITS: `Rule will add prefix in front of selected number and character separator between prefix and digits.\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.'\nOptionally number of digits staring from the begining of a number can be specified, otherwisee the whole number will be taken for transformation\nExample: Prefix:CN, Separator:'-'\n123456789 transforms to CN-123456789`,
	PREFIX_SEPARATOR_DIGITS_SEPARATOR_SUFFIX: `Rule will add prefix in front of selected number, then will add character separator between prefix and digits, then it will add suffix after the digits and character separator between digits nad suffix.\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.\nExample: Prefix: MY, FirstSeparator: '-', Digits: 6, Second Separator: '-', Suffix: A\nMY123456 transforms to MY-123456-A`,
	DIGITS_ONLY: `Rule will extract digits from selected number\nIf Digits field is populated, it will extract provided number of digits in sequence, otherwise it gets the whole sequence.\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.\nExample: MY-123456-A transforms to 123456\nExample: MY-123456-A transforms to 123 if Digits field is set to 3`,
	DIGITS_DECIMAL: `Rule will extract diigts from selected number together with decimal.\nIf Digits field is populated, it will extract provided number of digits in sequence, otherwise it gets the whole sequence.\nIf Decimals field is populated, it will extract provided number with that many decimal places in sequence, otherwise it gets the whole decimal.\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.\nExample: CN112233.0 transforms to 112233.0`,
	PREFIX_DIGITS: `Rule will add prefix in front of a provided number\nDigits filed is optional, if it's set it gets that many digits from the provided number\nExample: Prefix:CN, Digits:6, Provided Number: 123456789\n123456789 transforms to CN123456`,
	NUMBER_PLAIN: `Rule does not transform the provided number`,
	ALERT_ONLY: 'Rule will show provided alert message for selected number',
	COUNTRY_CODE_OR_OPTIONAL_PREFIX_FIRST: `Rule will add Country Code as prefix if search term includes country code, otherwise it will add optional provided prefix before selected number\nFor Option - for what number rule should be applied.\nBase Option - transformation will be done based on this provided value.\nExample: Prefix:AB, number:123456  region: San Marino\nif Search Term does not includes region code then it transforms to AB123456\nif Search Term  includes region code (SM) then it transforms to SM123456\n`,
};

export const TRANSFORMATION_OBJECT_TYPE = [
	{ label: '', value: '' },
	{ label: 'Search Term', value: 'SEARCH_TERM' },
	{ label: 'Application Number', value: 'APPLICATION_NUMBER' },
	{ label: 'Patent Number', value: 'PATENT_NUMBER' },
];

export const TRANSFORMATION_BASE_OBJECT = [
	{ label: '', value: '' },
	{ label: 'Application Number', value: 'APPLICATION_NUMBER' },
	{ label: 'Patent Number', value: 'PATENT_NUMBER' },
	{ label: 'PCT Number', value: 'PCT_NUMBER' },
];

export const RATE_CONFIRMED_BY = {
	AGENT: 'AGENT',
	CLIENT: 'CLIENT',
	MARKET: 'MARKET',
	AZAMI: 'AZAMI',
};

export const QUOTES_TABS = {
	1: 'Services and Technologies',
	2: 'Identifiers',
	3: 'Regions',
	4: 'Details',
	5: 'Additional Information',
	6: 'Associates',
};

export const DEBOUNCE_DELAY_MS = 500;

export const QB_NUM_OF_ASSOC_DISPLAYED = 3;

// Domains are base64-encoded to prevent direct exposure of sensitive information.
export const DOMAINS = {
	OPV2: [
		'YXphbWlsb2NhbA==', // azamilocal
		'YXphbWkubG9jYWw=', // azami.local
		'cWExLnYyLmF6YW1pZ2xvYmFsLmNvbQ==', // qa1.v2.azamiglobal.com
		'cWEyLnYyLmF6YW1pZ2xvYmFsLmNvbQ==', // qa2.v2.azamiglobal.com
		'cWEzLnYyLmF6YW1pZ2xvYmFsLmNvbQ==', // qa3.v2.azamiglobal.com
		'c3RhZ2UuY2hvaWNlaXAuY29t', // stage.choiceip.com
		'YXBwLXN0YWdlLmF6YW1pZ2xvYmFsLmNvbQ==', // app-stage.azamiglobal.com
		'djIuYXphbWlnbG9iYWwuY29t', // v2.azamiglobal.com
	],
	IPEER: [
		'aXBlZXIuYWk=', // ipeer.ai
		'aXBlZXIubG9jYWw=', // ipeer.local
		'YXBwLXN0YWdlLmlwZWVyLmFp', // app-stage.ipeer.ai
		'cWExLmlwZWVyLmFp', // qa1.ipeer.ai
		'cWEyLmlwZWVyLmFp', // qa2.ipeer.ai
		'cWEzLmlwZWVyLmFp', // qa3.ipeer.ai
	],
};

export const QUOTES_LOCATION = '/quotes';

export const ACCEPTED_FILE_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'image/jpeg',
	'image/png',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

/////////////////////////////////////////////////

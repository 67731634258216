import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';

const AppZonesBase = (props) => {
	let matchFound = false;
	const childrenWithProps = React.Children.map(props.children, (child) => {
		if (!matchFound && React.isValidElement(child)) {
			if (child.type.displayName === 'Private' && props.auth.loggedIn) {
				matchFound = true;
				return (
					<Switch>
						{React.Children.map(child.props.children, (c) => {
							return React.cloneElement(c, { path: c.props.route.path, exact: c.props.exact });
						})}
					</Switch>
				);
			} else if (child.type.displayName === 'Public' && !props.auth.loggedIn) {
				matchFound = true;
				return (
					<Switch>
						{React.Children.map(child.props.children, (c) => {
							return React.cloneElement(c, { path: c.props.route.path, exact: c.props.exact });
						})}
					</Switch>
				);
			}
		}
		// else {
		//     if (location.pathname !== '/') {
		//         localStorage.setItem('preRedirectLocation', location.pathname)
		//     }
		//     return <Redirect to="/" />
		// }
	});
	return childrenWithProps;
};

const Private = (props) => props.children;
Private.displayName = 'Private';

const Public = (props) => props.children;
Public.displayName = 'Public';

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const AppZones = connect(mapStateToProps)(AppZonesBase);

export { AppZones, Public, Private };

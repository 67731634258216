import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useQuery from 'services/hooks/useQuery';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { RoleBox } from 'com/util/RoleBox';
import AppLink from 'com/util/AppLink';

import DataTable, { Paging } from 'com/widgets/DataTable';
import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';

import Input from 'com/ui/Input';
import Button from 'com/ui/Button';
import LoaderOverlay from 'com/ui/LoaderOverlay';
import TagSelect from 'com/ui/TagSelect';
import Switch from 'com/ui/Switch';

import { EXTERNAL_ROLES, PAGE_TITLE, ROLE_TYPES, SERVICE_KEYS, QUOTE_STATUS, UTILITY_MODEL_PRIORITY_TYPE, DEADLINE_LABELS } from 'data/constants';

import Quotes from 'services/rest/quotes';
import Estimates from 'services/rest/estimates';
import Reports from 'services/rest/reports';
import Settings from 'services/rest/settings';
import EventLoggerEvents from 'services/rest/event_logger';
import { validateInstructionDeadline } from 'services/validators/quotes';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_ADD_REGION from 'assets/images/icons/globe_plus_icon.svg';
import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';
import ICON_PDF_ACTION_MENU from 'assets/images/icons/ico_pdf_action_menu.svg';
import ICON_PDF_ACTION_MENU_DISABLED from 'assets/images/icons/ico_pdf_action_menu_disabled.svg';
import ICON_EXCEL_ACTION_MENU from 'assets/images/icons/ico_excel_action_menu.svg';
import ICON_EXCEL_ACTION_MENU_DISABLED from 'assets/images/icons/ico_excel_action_menu_disabled.svg';
import ICON_HIDE_QUOTE from 'assets/images/icons/ico_hide_quote.svg';
import AppDate from 'com/util/AppDate';

import { roundUp } from 'services/strings';
import Firms from 'services/rest/firms';
import CasesRegions from 'services/rest/cases-regions';

const QuotesList = () => {
	useTitle(PAGE_TITLE.QUOTES);
	const user = useSelector((state) => state.auth.user);
	const tableHeadersAdminInit = [
		{ title: 'Case No.', field: 'case_number', type: 'string', sort: 'none', sortable: true, sort: 'down' },
		{ title: 'Client', field: 'client_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'App. No./Applicant', field: 'applicant_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Deadline', field: 'due_date', type: 'date', sort: 'none', sortable: true },
		{ title: 'Service', field: 'service_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Status', field: 'status', type: 'string', sort: 'none', sortable: true },
		{ title: 'Estimate Date', field: '_created', type: 'date', sort: 'none', sortable: true },
		{ title: 'Last Edited', field: '_modified', type: 'date', sort: 'none', sortable: true },
		{ title: '', field: 'action', type: 'strung', sort: 'none', sortable: false },
		{ title: '', field: 'action', type: 'strung', sort: 'none', sortable: false },
	];

	const tableHeadersMemberInit = [
		{ title: 'Ref. Num.', field: 'case_number', type: 'string', sort: 'none', sortable: true, sort: 'down' },
		{ title: 'Client', field: 'client_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'App. No./Applicant', field: 'applicant_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Client Ref.', field: 'client_ref_number', type: 'string', sort: 'none', sortable: true },
		{ title: 'Deadline', field: 'due_date', type: 'date', sort: 'none', sortable: true },
		{ title: 'Service', field: 'service_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Status', field: 'service_name', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: 'action', type: 'strung', sort: 'none', sortable: false },
		{ title: '', field: 'action', type: 'strung', sort: 'none', sortable: false },
		{ title: '', field: 'action', type: 'strung', sort: 'none', sortable: false },
	];

	//place holder values for quote status filter
	const quoteStatus = [
		{ label: 'Select status', value: '' },
		{ label: 'Draft', value: QUOTE_STATUS.DRAFT },
		{ label: 'In Progress', value: QUOTE_STATUS.PENDING },
		{ label: 'Complete', value: QUOTE_STATUS.PUBLISHED },
	];

	const SHOW_MOST_RECENT_QUOTES_TOOLTIP =
		'Displays only quotes before or up to 1 month after the deadline or quotes requested within the past year if there is no deadline. Deselecting this option will show all quotes regardless of deadline.';
	const INCLUDE_HIDDEN_QUOTES_TOOLTIP = "Displays all quotes including those manually hidden by a member of the user's firm.";

	const [tableHeadersAdmin, setTableHeadersAdmin] = useState(tableHeadersAdminInit);
	const [tableHeadersMember, setTableHeadersMember] = useState(tableHeadersMemberInit);
	const [showLoader, setShowLoader] = useState(false);
	const [quotes, setQuotes] = useState([]);
	const [paging, setPaging] = useState({ total_rows: 0, page: 1, limit: Paging.PAGE_SIZE[0].value });
	const [pageChange, setPageChange] = useState(1);
	const [services, setServices] = useState([]);
	const query = useQuery();
	const caseNumberQuery = query.get('case-number');
	const [filterData, setFilterData] = useState({
		case_number: caseNumberQuery ? caseNumberQuery : '',
		application_number: '',
		client_name: '',
		deadline: '',
		order_by: [{ field: '_modified', direction: 'down' }],
		applicant_name: '',
		estimate_date: '',
		service: [],
		status: [],
		edited_on: '',
		edited_by: '',
		hidden: '0',
		client_ref_number: '',
	});
	const [showMostRecentQuotes, setShowMostRecentQuotes] = useState(true);
	const [showHiddenQuotes, setShowHiddenQuotes] = useState(false);
	const history = useHistory();
	const { getFilterValue, setFilterValue } = useFilterStorage();
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [serviceForModal, setServiceIdForModal] = useState(false);
	const [clickedActionMenuCell, setClickedActionMenuCell] = useState('');

	const getQuotes = async () => {
		try {
			let f = getFilterValue();
			if (f) setFilterData(f);
			setShowLoader(true);
			let filter = {
				...(f ? f : filterData),
				order_by: filterData.order_by.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
				page: paging.page,
				limit: paging.limit,
				most_recent: false,
			};
			if (user.roleType === ROLE_TYPES.EXTERNAL && showMostRecentQuotes) filter = { ...filter, most_recent: true };
			if (user.roleType === ROLE_TYPES.EXTERNAL && showHiddenQuotes) filter = { ...filter, hidden: '' };
			const quotesData = await Quotes.GetAllForUser(filter);
			setQuotes(quotesData.data);
			setPaging({ ...paging, total_rows: quotesData.total_rows });
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
			console.log(error);
		}
	};

	const filterChange = (e) => {
		const { name, value } = e.target;
		setFilterData({ ...filterData, [name]: value });
	};

	const filterSubmit = () => {
		setPaging({ ...paging, total_rows: 0, page: 1 });
		setPageChange(pageChange + 1);
		setFilterValue(filterData);
		EventLoggerEvents.EventLogger({ event: `Search Quotes`, data: { user: user } });
	};

	const clearForm = () => {
		setFilterData({
			case_number: '',
			application_number: '',
			client_name: '',
			deadline: '',
			order_by: [{ field: 'case_number', direction: 'down' }],
			applicant_name: '',
			estimate_date: '',
			service: [],
			status: [],
			edited_on: '',
			edited_by: '',
			hidden: '0',
			client_ref_number: '',
		});
		setTableHeadersAdmin(tableHeadersAdminInit);
		setTableHeadersMember(tableHeadersMemberInit);
		setShowHiddenQuotes(false);
		filterSubmit();
		setFilterValue(null);
	};

	const pagingChange = (p, l) => {
		setPaging({ ...paging, page: p, limit: l });
		setPageChange(pageChange + 1);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const columnSortAdmin = (col) => {
		setTableHeadersAdmin(
			tableHeadersAdmin.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const columnSortMember = (col) => {
		setTableHeadersMember(
			tableHeadersMember.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
		setPageChange(pageChange + 1);
	};

	const goToDetails = (e) => {
		e.stopPropagation();
		history.push(`/quotes/${e.target.closest('tr').dataset.qid}/revisions`);
	};

	const goToQuoteDetails = (e) => {
		e.stopPropagation();
		history.push(`/quotes/${e.target.closest('tr').dataset.qid}/edit`);
	};

	const goToDetailsExternal = (e) => {
		e.stopPropagation();
		history.push(`/quotes/${e.target.closest('tr').dataset.qid}/estimate`);
	};

	const finishInstruction = (e) => {
		e.stopPropagation();
		history.push(`/quotes/instruct-without-pre-existing/${e.target.closest('tr').dataset.qid}/draft`);
	};

	const finishDraftQuote = (e) => {
		e.stopPropagation();
		history.push(`/quotes/add/${e.target.closest('tr').dataset.qid}/draft`);
	};

	useEffect(() => {
		(async () => {
			try {
				const servicesData = await Settings.GetServices();
				setServices([
					{ label: 'Select service', value: '' },
					...servicesData.map((s) => {
						return { label: s.name, value: s.id, key: s.key, id: s.id };
					}),
				]);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		getQuotes();
	}, [pageChange, showMostRecentQuotes, showHiddenQuotes]);

	const handleActionInstruct = (e) => {
		e.stopPropagation();
		const qid = e.target.dataset.qid;
		if (user.roleType === ROLE_TYPES.INTERNAL) return history.push(`/quotes/${qid}/revisions/instruct`);
		return history.push(`/quotes/${qid}/estimate/instruct`);
	};

	const unInstructableQuoteAction = (e) => {
		e.stopPropagation();
		let service = services.find((s) => s.id === e.target.dataset.service);
		if (service?.key === SERVICE_KEYS.DESIGN) {
			setServiceIdForModal(true);
		} else {
			setServiceIdForModal(false);
		}
		setInstructionDeadlineModal(true);
	};

	const handleActionFinishDraft = (e) => {
		e.stopPropagation();
		const qid = e.target.dataset.qid;
		history.push(`/quotes/add/${qid}/draft`);
	};
	const handleActionFinishDraftInstructions = (e) => {
		e.stopPropagation();
		const qid = e.target.dataset.qid;
		history.push(`/quotes/instruct-without-pre-existing/${qid}/draft`);
	};

	const handleActionsPdf = async (e) => {
		e.stopPropagation();
		const { qid, eid } = e.target.closest('tr').dataset;
		if (!eid) return;
		EventLoggerEvents.EventLogger({ event: 'Downloaded Quote - Quotes List', data: { estimate_id: eid, user: user } });

		try {
			await Reports.DownloadEstimateReport(eid, qid, {});
		} catch (error) {
			console.log('error', error);
		}
	};

	// This should be refactored, and the entire logic should happen on the backend, as this is duplicate code on the frontend. We should have only one logic that handles the Excel export on the backend.
	const handleActionsExcel = async (e) => {
		e.stopPropagation();
		try {
			const { qid, eid } = e.target.closest('tr').dataset;
			if (!eid) return;
			await Reports.DownloadXlsxEstimateReport(eid, qid);
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleActionsHide = (e) => {
		e.stopPropagation();
		try {
			let { id } = e.currentTarget.parentNode.dataset;
			Quotes.Hide(id);
			setPageChange(pageChange + 1);

			let currentQuote = quotes.find((q) => q.id == id);
			let { is_hidden } = currentQuote;
			let event = is_hidden ? `Member Unhid Quote` : `Member Hid Quote`;
			EventLoggerEvents.EventLogger({ event: event, data: { quote_id: id, user: user } });
		} catch (err) {}
	};

	const getMenuActions = (id, eid) => {
		let currentQuote = quotes.find((q) => q.id == id);
		let { is_hidden } = currentQuote;

		return [
			{
				label: 'Export PDF',
				action: handleActionsPdf,
				dataset: { id: id },
				ico: ICON_PDF_ACTION_MENU,
				roles: ['admin', 'user', 'member', 'member_assistant', 'saas_user'],
				disabled: !eid && true,
			},
			{
				label: 'Export Excel',
				action: handleActionsExcel,
				dataset: { id: id },
				ico: ICON_EXCEL_ACTION_MENU,
				roles: ['admin', 'user', 'member', 'member_assistant', 'saas_user'],
				disabled: !eid && true,
			},
			is_hidden ?
				{
					label: 'Show',
					action: handleActionsHide,
					dataset: { id: id },
					ico: ICON_HIDE_QUOTE,
					roles: ['user', 'member', 'member_assistant', 'saas_user'],
					title: 'Show this quote on the Quotes List.',
				}
			:	{
					label: 'Hide',
					action: handleActionsHide,
					dataset: { id: id },
					ico: ICON_HIDE_QUOTE,
					roles: ['user', 'member', 'member_assistant', 'saas_user'],
					title: 'Hide this quote from the Quotes List. To show it again, search for the quote by switching on "Include hidden quotes" on the Filters panel. Then click "Show" from this menu.',
				},
		];
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	const onChangeMostRecent = () => setShowMostRecentQuotes((state) => !state);
	const onChangeHiddenQuotes = () => setShowHiddenQuotes((state) => !state);

	const isUserInternal = user.roleType === ROLE_TYPES.INTERNAL;

	const validateInstructionsDeadline = (date, service_id, services, priority_type) => {
		let invalid_deadline = false;
		if (date && user.roleType === ROLE_TYPES.EXTERNAL) {
			invalid_deadline = validateInstructionDeadline(new Date(date), service_id, services, priority_type);
		}
		return invalid_deadline;
	};

	const instructionDeadlineModalClose = () => {
		setInstructionDeadlineModal(false);
	};

	const instructionDeadlineModalCancel = [{ primary: true, label: 'Cancel', action: instructionDeadlineModalClose, theme: 'azami-ghost' }];

	const toggleActionMenuCell = (e) => {
		e.stopPropagation();
		const targetCell = e.target.closest('tr').dataset.qid;

		setClickedActionMenuCell((prevTargetCell) => (prevTargetCell === targetCell ? '' : targetCell));
	};

	useEffect(() => {
		document.addEventListener('click', () => setClickedActionMenuCell(''));
		return () => document.addEventListener('click', setClickedActionMenuCell(''));
	}, []);

	return (
		<AppTemplate title="Quotes" headerIcon="quotes">
			<AppTemplate.Sidebar>
				<div className="quotes-list__sidebar">
					<h4 className="quotes-list__sidebar-header">
						<img src={ICON_FUNNEL} alt="" />
						Filters
					</h4>
					<Input
						label={isUserInternal ? 'Case Number' : 'Reference Number'}
						name="case_number"
						theme="dark"
						placeholder={isUserInternal ? 'Enter case number' : 'Enter reference number'}
						value={filterData.case_number}
						onChange={filterChange}
						onKeyDown={handleKeyDown}
					/>
					<RoleBox roles={EXTERNAL_ROLES}>
						<div className="quotes-list__sidebar-switch-group">
							<Switch label="Show most recent" theme="blurple" value={showMostRecentQuotes} onChange={onChangeMostRecent} title={SHOW_MOST_RECENT_QUOTES_TOOLTIP} />
							<Switch label="Include hidden quotes" theme="blurple" value={showHiddenQuotes} onChange={onChangeHiddenQuotes} title={INCLUDE_HIDDEN_QUOTES_TOOLTIP} />
						</div>
					</RoleBox>
					<Input label="Client" name="client_name" theme="dark" value={filterData.client_name} placeholder="Enter client name" onChange={filterChange} onKeyDown={handleKeyDown} />
					<Input
						label="Applicant"
						name="applicant_name"
						theme="dark"
						value={filterData.applicant_name}
						placeholder="Enter applicant name"
						onChange={filterChange}
						onKeyDown={handleKeyDown}
					/>
					<Input
						label="Application Number"
						name="application_number"
						theme="dark"
						placeholder="Enter application number"
						value={filterData.application_number}
						onChange={filterChange}
						onKeyDown={handleKeyDown}
					/>
					<RoleBox roles={EXTERNAL_ROLES}>
						<Input
							label="Client Reference Number"
							name="client_ref_number"
							theme="dark"
							placeholder="Enter client reference number"
							value={filterData.client_ref_number}
							onChange={filterChange}
							onKeyDown={handleKeyDown}
						/>
					</RoleBox>
					<Input label="Deadline" name="deadline" theme="dark" type="date" value={filterData.deadline} onChange={filterChange} />
					<Input label="Estimate Date" name="estimate_date" theme="dark" type="date" value={filterData.estimate_date} onChange={filterChange} />
					<TagSelect label="Service" name="service" theme="dark" options={services} values={filterData.service} onChange={filterChange} />
					<TagSelect label="Status" name="status" theme="dark" options={quoteStatus} values={filterData.status} onChange={filterChange} />
					<Input label="Edited By" name="edited_by" theme="dark" value={filterData.edited_by} placeholder="Enter name" onChange={filterChange} onKeyDown={handleKeyDown} />
					<Input label="Edited On" name="edited_on" theme="dark" type="date" value={filterData.edited_on} onChange={filterChange} />
					<div className="quotes-list__sidebar-actions">
						<Button theme="ghost" onClick={clearForm}>
							Clear
						</Button>
						<Button onClick={filterSubmit}>Apply Filters</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				<AppLink className="form-button blurple" to="/quotes/add">
					Request Quote
				</AppLink>
			</AppTemplate.Header>
			<AppTemplate.Content>
				{instructionDeadlineModal ?
					<InstructionDeadlineModal designService={serviceForModal} footerActions={instructionDeadlineModalCancel} closeHandler={instructionDeadlineModalClose} title="China Extension Fee" />
				:	null}
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<RoleBox roles={['user', 'member', 'member_assistant', 'saas_user']}>
						<DataTable fixedHeader={true} columns={tableHeadersMember} onColumnSort={columnSortMember} customClassName="quotes-list__data-table">
							{quotes.length >= 0 ?
								quotes.map((q) => {
									let unInstructable = validateInstructionsDeadline(q.due_date, q.service_id, services, q.priority_type);
									return (
										<tr
											key={q.id}
											onClick={
												q.draft && q.state === 'CASE' ? finishInstruction
												: q.draft && q.state === 'QUOTE' ?
													finishDraftQuote
												:	goToDetailsExternal
											}
											data-qid={q.id}
											data-eid={q.last_published_estimate}
										>
											<DataTable.Cell>{q.case_number}</DataTable.Cell>
											<DataTable.PersonCell subtext={`${q.client_first_name} ${q.client_last_name}`} showIco={false}></DataTable.PersonCell>
											<DataTable.PersonCell subtext={q.applicant_name} showIco={false}>
												{q.application_number}
											</DataTable.PersonCell>
											<DataTable.Cell>{q.client_ref_number}</DataTable.Cell>
											<DataTable.DateCell>{q.due_date}</DataTable.DateCell>
											<DataTable.Cell>{q.service_name}</DataTable.Cell>
											<DataTable.Cell>
												<div className={`quotes-list__status-${q.status}`}>{q.status === QUOTE_STATUS.PUBLISHED ? 'Complete' : q.status?.toLowerCase()}</div>
											</DataTable.Cell>
											<DataTable.Cell>
												<RoleBox roles={['user', 'member', 'member_assistant']}>
													{!q.draft ?
														<div className="quotes-list__data-table-globe-icon" onClick={goToQuoteDetails}>
															<img src={ICON_ADD_REGION} alt="" />
														</div>
													:	null}
												</RoleBox>
											</DataTable.Cell>
											<DataTable.Cell>
												{(user.role.includes('saas_user') && !q.fully_instructed) || !user.role.includes('saas_user') ?
													q.draft && q.state === 'CASE' ?
														<Button className={'quotes-list__button'} theme="azami-ghost" onClick={handleActionFinishDraftInstructions} data-qid={q.id}>
															Finish&nbsp;Instructing&nbsp;Case
														</Button>
													: q.draft && q.state === 'QUOTE' ?
														<Button className={'quotes-list__button'} theme="azami-ghost" onClick={handleActionFinishDraft} data-qid={q.id}>
															Finish&nbsp;Draft&nbsp;Quote
														</Button>
													:	<Button
															className={'quotes-list__button'}
															theme="blurple"
															onClick={unInstructable ? unInstructableQuoteAction : handleActionInstruct}
															data-qid={q.id}
															data-service={q.service_id}
														>
															Instruct
														</Button>

												:	null}
											</DataTable.Cell>
											<DataTable.ActionsMenuCell
												onClick={toggleActionMenuCell}
												customClassName={`quotes-list__action ${clickedActionMenuCell === q.id && 'active'}`}
												actions={getMenuActions(q.id, q.last_published_estimate)}
											></DataTable.ActionsMenuCell>
										</tr>
									);
								})
							:	null}
						</DataTable>
					</RoleBox>
					<RoleBox roles={['admin', 'case_manager', 'case_manager_manager', 'estimates', 'finance', 'sales', 'sales_manager']}>
						<DataTable fixedHeader={true} columns={tableHeadersAdmin} onColumnSort={columnSortAdmin} customClassName="quotes-list__data-table">
							{quotes.length >= 0 &&
								quotes.map((q) => {
									return (
										<tr
											key={q.id}
											onClick={
												q.draft && q.state === 'CASE' ? finishInstruction
												: q.draft && q.state === 'QUOTE' ?
													finishDraftQuote
												:	goToDetails
											}
											data-qid={q.id}
											data-eid={q.last_published_estimate}
										>
											<DataTable.Cell>{q.case_number}</DataTable.Cell>
											<DataTable.PersonCell subtext={`${q.client_first_name} ${q.client_last_name}`} showIco={false}>
												{q.firm_name}
											</DataTable.PersonCell>
											<DataTable.PersonCell subtext={q.applicant_name} showIco={false}>
												{q.application_number}
											</DataTable.PersonCell>
											<DataTable.DateCell showIco={false}>{q.due_date}</DataTable.DateCell>
											<DataTable.Cell>{q.service_name}</DataTable.Cell>
											<DataTable.Cell>
												<div className={`quotes-list__status-${q.status}`}>{q.status?.toLowerCase()}</div>
											</DataTable.Cell>
											<DataTable.DateCell showIco={false}>{q._created}</DataTable.DateCell>
											<DataTable.EditedByCell user={`${q.edited_by_first_name ? q.edited_by_first_name : ''} ${q.edited_by_last_name ? q.edited_by_last_name : ''}`}>
												<AppDate>{new Date(q._modified)}</AppDate>
											</DataTable.EditedByCell>
											<DataTable.Cell>
												{q.draft && q.state === 'CASE' ?
													<Button className={'quotes-list__button'} theme="azami-ghost" onClick={handleActionFinishDraftInstructions} data-qid={q.id}>
														Finish&nbsp;Instructing&nbsp;Case
													</Button>
												: q.draft && q.state === 'QUOTE' ?
													<Button className={'quotes-list__button'} theme="azami-ghost" onClick={handleActionFinishDraft} data-qid={q.id}>
														Finish&nbsp;Draft&nbsp;Quote
													</Button>
												:	<Button className={'quotes-list__button'} onClick={handleActionInstruct} data-qid={q.id}>
														Instruct
													</Button>
												}
											</DataTable.Cell>
											<DataTable.ActionsMenuCell
												onClick={toggleActionMenuCell}
												customClassName={`quotes-list__action ${clickedActionMenuCell === q.id && 'active'}`}
												actions={getMenuActions(q.id, q.last_published_estimate)}
											></DataTable.ActionsMenuCell>
										</tr>
									);
								})}
						</DataTable>
					</RoleBox>
					<Paging totalRows={paging.total_rows} page={paging.page} limit={paging.limit} mask={5} onClick={pagingChange} />
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default QuotesList;

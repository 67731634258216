import React, { useEffect, useState } from 'react';

import Button from 'com/ui/Button';
import BooleanBadge from 'com/ui/BooleanBadge';

import { getFlag } from 'data/flags';

import Settings from 'services/rest/settings';
import PricingLevel from 'services/rest/pricing_levels';

import ICON_CLOSE from 'assets/images/icons/ico-close-darkblue.svg';

import Lists from 'com/pages/SystemSettingsEstimatorRules/lists';
import { CALCULATION_TYPE } from 'com/pages/SystemSettingsEstimatorRules/SystemSettingsEstimatorRulesDetails';
import AppDate from 'com/util/AppDate';

import './style.css';

const EstimatorRulesPreview = (props) => {
	const [services, setServices] = useState([{ label: 'All', value: '' }]);
	const [regions, setRegions] = useState([{ label: 'All', value: '' }]);
	const [currencies, setCurrencies] = useState([]);
	const [pricingLevels, setPricingLevels] = useState([]);
	const [languages, setLanguages] = useState([{ label: '', value: '' }]);

	useEffect(() => {
		(async () => {
			try {
				let [rs, ss, cs, pl, ls] = await Promise.all([
					Settings.GetRegions(),
					Settings.GetServices(),
					Settings.GetCurrencies(),
					PricingLevel.GetAll({ show_deleted: '0' }),
					Settings.GetLanguages(),
				]);
				setRegions([...regions, ...rs.map((r) => ({ ...r, label: r.name, value: r.id }))]);
				setServices([...services, ...ss.map((s) => ({ ...s, label: s.name, value: s.id }))]);
				setCurrencies(cs);
				setPricingLevels(pl);
				setLanguages([...languages, ...ls.map((l) => ({ label: l.name, value: l.id }))]);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	const idsToNames = (ids, array) => {
		if (ids && ids.length > 0) {
			return ids.map((i) => {
				return array.find((a) => a.id == i)?.name;
			});
		}
		return [];
	};
	const regionsToNameImg = (ids, array) => {
		if (ids && ids.length > 0) {
			return ids.map((i) => {
				let r = array.find((a) => a.id === i);
				return {
					id: i,
					name: r.name,
					flag: getFlag(r.code),
				};
			});
		}
		return [];
	};

	const calculationTypeLabel = (str) => {
		let calculation_type_label = '';
		switch (str) {
			case CALCULATION_TYPE.ABSOLUTE_VALUE:
				calculation_type_label = 'Absolute Value';
				break;
			case CALCULATION_TYPE.BY_AMOUNT:
				calculation_type_label = 'By Amount';
				break;
			case CALCULATION_TYPE.SELECTED_UNSELECTED:
				calculation_type_label = 'Selected/Unselected';
				break;
			case CALCULATION_TYPE.FROM_LIST:
				calculation_type_label = 'From List';
				break;
			case CALCULATION_TYPE.VALUELESS:
				calculation_type_label = 'Valueless';
				break;
			case CALCULATION_TYPE.DISCOUNT:
				calculation_type_label = 'Discount';
				break;
			default:
				calculation_type_label = '';
				break;
		}

		return calculation_type_label;
	};

	const feeTypeLabel = (str) => {
		let label = '';
		switch (str) {
			case 'OFFICIAL':
				label = 'Official';
				break;
			case 'TRANSLATION':
				label = 'Translation';
				break;
			case 'PROFESSIONAL':
				label = 'Professional';
				break;
			default:
				label = '';
				break;
		}
		return label;
	};

	const calculationModeLabel = (str) => {
		let field = Lists.calculationByAmountMode.find((item) => item.value == str);
		return field ? field.label : '';
	};

	const calculationFieldLabel = (type, str) => {
		let list = '';
		switch (type) {
			case CALCULATION_TYPE.BY_AMOUNT:
				list = Lists.calculationByAmountField;
				break;
			case CALCULATION_TYPE.SELECTED_UNSELECTED:
				list = Lists.calculationSelectUnselectedField;
				break;
			case CALCULATION_TYPE.FROM_LIST:
			case CALCULATION_TYPE.DISCOUNT:
				list = Lists.calculationValueFromListField;
				break;
		}

		if (list) {
			let field = list.find((item) => item.value == str);
			return field ? field.label : '';
		}

		return '';
	};

	const fromListFieldValueLabel = (type, str) => {
		let list = '';
		switch (type) {
			case 'ENTITY_SIZE':
				list = Lists.calculationValueFromListValueEntitySize;
				break;
			case 'LOCATION_OF_SEARCH':
				list = Lists.calculationValueFromListValueLocationOfSearch;
				break;
			case 'APPLICATION_LANGUAGE':
				list = languages;
				break;
			case 'UTILITY_MODEL_PRIORITY':
				list = Lists.calculationValueFromListValueUtilityModelPriority;
				break;
		}

		if (list) {
			let field = list.find((item) => item.value == str);
			return field ? field.label : '';
		}

		return '';
	};

	const currencyLabel = (currencyId) => {
		let rule_currency = currencies.find((currency) => currencyId == currency.id);
		let rule_currency_label = rule_currency ? `${rule_currency.symbol} (${rule_currency.name})` : '';
		return rule_currency_label;
	};

	const servicesLabel = (r_services) => {
		let rule_services = idsToNames(r_services, services).join(', ');
		return rule_services;
	};

	const groupBy = (xs, key) => {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	const objToArray = (obj) => {
		let out = [];
		Object.keys(obj).forEach(function (key, index) {
			out.push(obj[key]);
		});
		return out;
	};

	const getBaseLevelLabel = () => {
		let base = pricingLevels.find((p) => p.is_base === true);
		return base?.name;
	};

	const getBaseLevelValueAbs = () => {
		let base = pricingLevels.find((p) => p.is_base === true);
		if (base) {
			let fee = props.rule.fees.find((f) => f.level_id === base.id);
			return fee ? fee.value : '/';
		}
		return '/';
	};

	const getBaseLevelValueAmount = (range_fees) => {
		let base = pricingLevels.find((p) => p.is_base === true);
		if (base) {
			let fee = range_fees.find((f) => f.level_id === base.id);
			return fee ? fee.value : '/';
		}
		return '/';
	};

	return (
		<div className={`estimator-rules-preview ${props.show ? 'estimator-rules-preview__open' : ''}`}>
			{props.rule && (
				<div className="estimator-rules-preview__wrapper">
					<div className="estimator-rules-preview__header">
						<span>{props.rule.name}</span>
						<span onClick={props.closeHandlrer} className="estimator-rules-preview__header__close">
							<img src={ICON_CLOSE} alt="" />
						</span>
					</div>
					<div className="estimator-rules-preview__contaier">
						<div className="estimator-rules-preview__contaier__content">
							<div className="estimator-rules-preview__contaier__content-first-row">
								<div className="estimator-rules-preview__contaier__content-first-row-box">
									<span>Calculation Type</span>
									<span className="estimator-rules-preview__contaier__content-first-row-box-value">{calculationTypeLabel(props.rule.calculation_type)}</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-first-row-box">
									<span>Currency</span>
									<span className="estimator-rules-preview__contaier__content-first-row-box-value">{currencyLabel(props.rule.currency)}</span>
								</div>
							</div>
							{[CALCULATION_TYPE.BY_AMOUNT, CALCULATION_TYPE.SELECTED_UNSELECTED, CALCULATION_TYPE.FROM_LIST, CALCULATION_TYPE.DISCOUNT].includes(props.rule.calculation_type) && (
								<div className="estimator-rules-preview__contaier__content-row">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>Field</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>{calculationFieldLabel(props.rule.calculation_type, props.rule.calculation_field)}</span>
									</div>
								</div>
							)}
							{props.rule.calculation_type === CALCULATION_TYPE.BY_AMOUNT && (
								<div className="estimator-rules-preview__contaier__content-row">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>Mode</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>{calculationModeLabel(props.rule.calculation_mode)}</span>
									</div>
								</div>
							)}
							{props.rule.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED && (
								<div className="estimator-rules-preview__contaier__content-row">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>Mode</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>{props.rule.calculation_field_equals ? 'Selected' : 'Unselected'}</span>
									</div>
								</div>
							)}
							{[CALCULATION_TYPE.FROM_LIST, CALCULATION_TYPE.DISCOUNT].includes(props.rule.calculation_type) && (
								<div className="estimator-rules-preview__contaier__content-row">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>Field Value</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>{fromListFieldValueLabel(props.rule.calculation_field, props.rule.calculation_value2)}</span>
									</div>
								</div>
							)}
							{[CALCULATION_TYPE.SELECTED_UNSELECTED, CALCULATION_TYPE.FROM_LIST, CALCULATION_TYPE.DISCOUNT].includes(props.rule.calculation_type) && (
								<div className="estimator-rules-preview__contaier__content-row">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>Value</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>{props.rule.calculation_value1}</span>
									</div>
								</div>
							)}
							{props.rule.calculation_type == CALCULATION_TYPE.ABSOLUTE_VALUE && (
								<div>
									{props.rule.use_pricing_level ?
										pricingLevels.map((pl) => {
											let v = props.rule.fees.find((f) => f.level_id === pl.id);
											return (
												<div key={pl?.id} className="estimator-rules-preview__contaier__content-row">
													<div className="estimator-rules-preview__contaier__content-row-left">
														<span>{pl?.name}</span>
													</div>
													<div className="estimator-rules-preview__contaier__content-row-right">
														<span>{`: ${v?.value || '/'}`}</span>
													</div>
												</div>
											);
										})
									:	<div className="estimator-rules-preview__contaier__content-row">
											<div className="estimator-rules-preview__contaier__content-row-left">
												<span>{getBaseLevelLabel()}</span>
											</div>
											<div className="estimator-rules-preview__contaier__content-row-right">
												<span>{`: ${getBaseLevelValueAbs()}`}</span>
											</div>
										</div>
									}
								</div>
							)}
							{props.rule.calculation_type == CALCULATION_TYPE.BY_AMOUNT &&
								objToArray(groupBy(props.rule.fees, 'group')).map((range, i) => {
									return (
										<React.Fragment key={i}>
											<div key={range[0].group} className="estimator-rules-preview__contaier__content-row">
												<div className="estimator-rules-preview__contaier__content-row-left">
													<span>Range {range[0].group + 1}</span>
												</div>
												<div className="estimator-rules-preview__contaier__content-row-right">
													<span>
														{range[0].range_from} - {range[0].range_to}
													</span>
												</div>
											</div>
											{props.rule.use_pricing_level ?
												pricingLevels.map((pl) => {
													let v = props.rule.fees.find((f) => f.level_id === pl.id && f.group === range[0].group);
													return (
														<div key={`${pl.id}__${range[0].group}`} className="estimator-rules-preview__contaier__content-row by-amout-pricing-range">
															<div className="estimator-rules-preview__contaier__content-row-left" />
															<div className="estimator-rules-preview__contaier__content-row-right">
																<span className="estimator-rules-preview__contaier__content-by-amout-range-pricng-level">{pl.name}</span>
																<span>{`: ${v?.value || '/'}`}</span>
															</div>
														</div>
													);
												})
											:	<div className="estimator-rules-preview__contaier__content-row by-amout-pricing-range">
													<div className="estimator-rules-preview__contaier__content-row-left" />
													<div className="estimator-rules-preview__contaier__content-row-right">
														<span className="estimator-rules-preview__contaier__content-by-amout-range-pricng-level">{getBaseLevelLabel()}</span>
														<span>{`: ${getBaseLevelValueAmount(range)}`}</span>
													</div>
												</div>
											}
										</React.Fragment>
									);
								})}
							<div className="estimator-rules-preview__contaier__content-row">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>Fee</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>{feeTypeLabel(props.rule.fee_type)}</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row rule-options">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>
										<BooleanBadge value={!!props.rule.review_needed} />
									</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>Quote must be reviewed by Estimates Team</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row rule-options">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>
										<BooleanBadge value={!!props.rule.charge_once_multi_regions} />
									</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>Charge only once if activated for multiple regions</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row rule-options">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>
										<BooleanBadge value={!!props.rule.use_pricing_level} />
									</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>Pricing Level</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row rule-options">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>
										<BooleanBadge value={props.rule.disbursement_fee} />
									</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>Disbursement Fee</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row rule-options">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>Importance</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right">
									<span>{props.rule.optional_rule == 0 ? 'Mandatory' : 'Optional'}</span>
								</div>
							</div>
							{props.rule.optional_rule == 1 ?
								<div className="estimator-rules-preview__contaier__content-row rule-options">
									<div className="estimator-rules-preview__contaier__content-row-left">
										<span>
											<BooleanBadge value={props.rule.optional_rule_preselected} />
										</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-row-right">
										<span>Preselected</span>
									</div>
								</div>
							:	null}
							{props.rule.optional_rule == 1 ?
								<div className="estimator-rules-preview__contaier__content-column rule-options">
									<div className="estimator-rules-preview__contaier__content-column-top">
										<span>Optional Rule Text</span>
									</div>
									<div className="estimator-rules-preview__contaier__content-column-bottom">
										<span>{props.rule.optional_rule_text ? props.rule.optional_rule_text : '/'}</span>
									</div>
								</div>
							:	null}
							<div className="estimator-rules-preview__contaier__content-column rule-options">
								<div className="estimator-rules-preview__contaier__content-column-top">
									<span>Services</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-column-bottom">
									<span>{servicesLabel(props.rule.services)}</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-column rule-options">
								<div className="estimator-rules-preview__contaier__content-column-top">
									<span>Regions</span>
								</div>
								{regionsToNameImg(props.rule.regions, regions).map((r) => {
									return (
										<div className="estimator-rules-preview__contaier__content-row-region-wrapper" key={r.id}>
											<span className="estimator-rules-preview__contaier__content-row-region-wrapper-flag">
												<img src={r.flag} alt="" />
											</span>
											<span className="estimator-rules-preview__contaier__content-row-region-wrapper-text">{r.name}</span>
										</div>
									);
								})}
							</div>
							<div className="estimator-rules-preview__contaier__content-column rule-options">
								<div className="estimator-rules-preview__contaier__content-column-top">
									<span>External Note</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-column-bottom">
									<span>{props.rule.external_note ? props.rule.external_note : '/'}</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-column rule-options">
								<div className="estimator-rules-preview__contaier__content-column-top">
									<span>Internal Note</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-column-bottom">
									<span>{props.rule.internal_note ? props.rule.internal_note : '/'}</span>
								</div>
							</div>
							<div className="estimator-rules-preview__contaier__content-row">
								<div className="estimator-rules-preview__contaier__content-row-left">
									<span>Last Edited</span>
								</div>
								<div className="estimator-rules-preview__contaier__content-row-right last-editet-by-field">
									<span>{props.rule.created_by_name ? props.rule.created_by_name : ''}</span>
									<span className="last-editet-by-field-date">
										<AppDate>{props.rule._modified}</AppDate>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{props.footerActions && props.rule ?
				<div className="estimator-rules-preview__footer">
					{props.footerActions.map((b, i) => {
						if (b.condition !== null && props.rule[b.condition.field] === b.condition.value) {
							return (
								<Button key={i} onClick={(e) => b.action(e, props.rule.parent_id)} theme={b.theme} data-id={props.rule.id}>
									{b.label}
								</Button>
							);
						} else if (b.condition === null) {
							return (
								<Button key={i} onClick={(e) => b.action(e, props.rule.parent_id)} theme={b.theme} data-id={props.rule.id}>
									{b.label}
								</Button>
							);
						}
					})}
				</div>
			:	null}
		</div>
	);
};

export default EstimatorRulesPreview;

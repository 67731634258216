import { useSelector, connect } from 'react-redux';
import { setQuoteData } from 'redux/ducks/quote';

import RadioButton from 'com/ui/RadioButton';
import Checkbox from 'com/ui/Checkbox';
import Switch from 'com/ui/Switch';
import Loader from 'com/ui/Loader';

import { UTILITY_MODEL_PRIORITY_TYPE, SERVICE_KEYS, ROLE_TYPES, RECORDAL_TYPES, INTERNAL_ROLES, EXTERNAL_ROLES } from 'data/constants';

import { RoleBox } from 'com/util/RoleBox';

import './style.css';

const Services = (props) => {
	const user = useSelector((state) => state.auth.user);

	const showInternalOnlyService = (service) => {
		if (user.role.includes('saas_user') && service.key === SERVICE_KEYS.RECORDAL) {
			return false;
		}

		if (service.key !== SERVICE_KEYS.PROSECUTION && service.key !== SERVICE_KEYS.OTHER) {
			return true;
		}

		return user.roleType === ROLE_TYPES.INTERNAL ? true : false;
	};

	const serviceUpdate = async (e) => {
		if (user.roleType === ROLE_TYPES.EXTERNAL) {
			let service = props.services.find((s) => s.id === e.target.value);
			let date;
			if (service.key === SERVICE_KEYS.PCT) {
				date = props.quote.earliest_priority_date || props.quote.intl_filing_date;
			} else {
				date = props.quote.earliest_priority_date;
			}
			if (date) {
				props.validateInstructionsDeadline(new Date(date), e.target.value, props.services, props.quote.priority_type);
			}
		}

		props.setQuoteData({ name: e.target.name, value: e.target.value });
		props.setQuoteData({ name: 'regions', value: [] });

		if (props.quote.application_number && props.quote.client_firm_id) {
			let duplicate = await props.checkDuplicates(e.target.value, props.quote.application_number, props.quote.client_firm_id);
			if (duplicate) {
				props.setErrors({
					...props.errors,
					application_number: 'Already Existing',
				});
				return;
			}
		}

		if (props.errors.application_number) {
			props.setErrors({
				...props.errors,
				application_number: '',
			});
		}
	};

	const priorityTypeUpdate = (e) => {
		if (props.quote.earliest_priority_date && user.roleType === ROLE_TYPES.EXTERNAL) {
			props.validateInstructionsDeadline(new Date(props.quote.earliest_priority_date), props.quote.service_id, props.services, e.target.value);
		}
		props.setQuoteData({ name: e.target.name, value: e.target.value });
	};

	const showByService = (service_id, service_keys) => {
		let service = props.services.find((s) => s.id == service_id);
		if (service) {
			if (service_keys.includes(service.key)) {
				return true;
			}
		}
		return false;
	};

	const technologyChange = (e) => {
		let application_technologies = [];
		if (props.quote.application_technologies) {
			if (e.target.checked) {
				application_technologies = [...props.quote.application_technologies, e.target.name];
			} else {
				application_technologies = [...props.quote.application_technologies.filter((ds) => ds !== e.target.name)];
			}
		} else {
			application_technologies = [e.target.name];
		}
		props.setQuoteData({ name: 'application_technologies', value: application_technologies });
	};

	return (
		<div className="quote-details__section-container">
			{props.newQuote ?
				<div className="quote-details__section-container-services-top">
					<div className="quote-details__section-container-services-top-left right-separator">
						<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'section-title-error' : ''}`}>Services</h4>
						<ul className="quote-details__service-list">
							{props.services.map((s) => {
								if (showInternalOnlyService(s)) {
									return (
										<li key={s.id}>
											<RadioButton
												label={s.name}
												name="service_id"
												value={s.id}
												checked={props.quote.service_id === s.id}
												onChange={serviceUpdate}
												themeCheck="blurple"
												customClassName="quote-details__service-list-service"
											/>
										</li>
									);
								} else {
									return null;
								}
							})}
						</ul>
					</div>
					<div className="quote-details__section-container-services-top-right">
						{showByService(props.quote.service_id, [SERVICE_KEYS.PCT, SERVICE_KEYS.PARIS, SERVICE_KEYS.UTILITY]) ?
							<>
								<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'error' : ''}`}>
									Technologies
									<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
										<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
											&#42;
										</span>
									</RoleBox>
								</h4>
								<ul className="quote-details__service-list">
									{props.technologiesData.map((s) => {
										let checked = props.quote.application_technologies && props.quote.application_technologies.includes(s.id);
										return (
											<li key={s.id}>
												<Checkbox label={s.name} name={s.id} theme="blurple" onChange={technologyChange} value={checked} checked={checked} themeUnchecked="blurple" />
											</li>
										);
									})}
								</ul>
							</>
						:	null}
					</div>
				</div>
			:	null}
			{!props.newQuote ?
				<>
					<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'section-title-error' : ''}`}>Services</h4>
					<ul className="quote-details__service-list">
						{props.services.map((s) => {
							if (showInternalOnlyService(s)) {
								return (
									<li key={s.id}>
										<RadioButton
											label={s.name}
											name="service_id"
											value={s.id}
											checked={props.quote.service_id === s.id}
											onChange={serviceUpdate}
											themeCheck="blurple"
											customClassName="quote-details__service-list-service"
										/>
									</li>
								);
							} else {
								return null;
							}
						})}
					</ul>
					{showByService(props.quote.service_id, [SERVICE_KEYS.PCT, SERVICE_KEYS.PARIS, SERVICE_KEYS.UTILITY]) ?
						<div>
							<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'error' : ''}`}>
								Technologies
								<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
									&#42;
								</span>
							</h4>
							<ul className="quote-details__service-list">
								{props.technologiesData.map((s) => {
									let checked = props.quote.application_technologies && props.quote.application_technologies.includes(s.id);
									return (
										<li key={s.id}>
											<Switch label={s.name} name={s.id} theme="blurple" onChange={technologyChange} value={checked} />
										</li>
									);
								})}
							</ul>
						</div>
					:	null}
				</>
			:	null}
			{showByService(props.quote.service_id, [SERVICE_KEYS.UTILITY]) ?
				<div>
					<h4 className={`quote-details__subtitle ${props.errors.priority_type ? 'error' : ''}`}>
						Priority Type
						<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
							&#42;
						</span>
					</h4>
					<ul className="quote-details__service-list">
						<li>
							<RadioButton
								label={'PCT NPE'}
								name="priority_type"
								value={UTILITY_MODEL_PRIORITY_TYPE.PCT_NPE}
								checked={props.quote.priority_type === UTILITY_MODEL_PRIORITY_TYPE.PCT_NPE}
								onChange={priorityTypeUpdate}
								themeCheck="blurple"
							/>
						</li>
						<li>
							<RadioButton
								label={'Paris Convention/Direct'}
								name="priority_type"
								value={UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION}
								checked={props.quote.priority_type === UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION}
								onChange={priorityTypeUpdate}
								themeCheck="blurple"
							/>
						</li>
					</ul>
				</div>
			:	null}

			{showByService(props.quote.service_id, [SERVICE_KEYS.RECORDAL]) ?
				<div>
					<h4 className={`quote-details__subtitle ${props.errors.recordal_type ? 'error' : ''}`}>
						Recordal type
						<span className="quote-details__service-list-techologies-required" title="This field is required">
							&#42;
						</span>
					</h4>
					<ul className="quote-details__service-list">
						{RECORDAL_TYPES.map((r, i) => {
							return (
								<li key={i}>
									<RadioButton label={r.label} name="recordal_type" value={r.value} checked={props.quote.recordal_type === r.value} onChange={serviceUpdate} themeCheck="blurple" />
								</li>
							);
						})}
					</ul>
				</div>
			:	null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		quote: state.quote,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setQuoteData: (data) => {
			dispatch(setQuoteData(data));
		},
	};
};

const Service = connect(mapStateToProps, mapDispatchToProps)(Services);

export default Service;
